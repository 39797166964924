import i18n from 'i18n-js';
import { DEFAULT_LANGUAGE } from '@src/constants/language';
import Eng from './eng.json';
import Rus from './rus.json';
import Est from './est.json';
import Esp from './esp.json';

i18n.fallbacks = true;
i18n.translations = { Eng, Rus, Est, Esp };
i18n.languages = ['Eng', 'Rus', 'Est', 'Esp'];
i18n.locale = DEFAULT_LANGUAGE;

export default i18n;