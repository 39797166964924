/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useStores } from '@src/stores';
import classNames from 'classnames';
import { COLORS_BUTTON } from './colors';
import SoonImage from '@src/assets/images/BlockchainGamePage/soon.png';

import styles from './button.module.scss';
import { default as UNIQ_ID_BUTTON } from './uniqID';

export { UNIQ_ID_BUTTON };

export const ColorType = {
  GREEN: 'GREEN',
  ORANGE: 'ORANGE',
  PURPLE: 'PURPLE',
  RED: 'RED',
};

export const SizeButton = {
  SMALL: 'SMALL',
  MEDIUM: 'MEDIUM',
};

const  DIFFERENCE_ACTUAL_AND_SVG_HEIGHT = 22;

const ButtonSVG = ({
  width,
  height,
  title,
  onClick,
  className = {},
  colorType,
  sizeButton,
  uniqID,
  valueScaleAnimation,
  disabled,
  isLoading,
}) => {
  const lightColor = COLORS_BUTTON[colorType].lightColor;
  const lightActiveColor = COLORS_BUTTON[colorType].lightActiveColor;
  const darkColor = COLORS_BUTTON[colorType].darkColor;
  const strokeColor = COLORS_BUTTON[colorType].strokeColor;
  const hoverColor = COLORS_BUTTON[colorType].hoverColor;

  const heightView = sizeButton === SizeButton.MEDIUM ? 135 : 88;

  // y coord for MEDIUM = 'calc(((135px-26.5px-6px)/2)+6px)' -> 57px
  // y coord for SMALL = 'calc(((88px-25px-5px)/2)+5px)' -> 34px
  const yCoordText = sizeButton === SizeButton.MEDIUM ? 57 : 34;
  return (
    <div className={className.Container}>
      <svg width={width} height={height} viewBox={`0 0 ${width} ${heightView}`} fill="none" xmlns="http://www.w3.org/2000/svg">
        {sizeButton === SizeButton.SMALL && (
          <g id={`ButtonSVGStyle_${uniqID}${disabled ? '_disabled' : ''}`} className={className.ActiveTransform} onClick={onClick}>
            <g filter={`url(#filter0_dii_857_280_${uniqID})`}>
              <path d={`M${width - 16.561} 26.5C${width - 13.882} 31.141 ${width - 13.882} 36.859 ${width - 16.561} 41.5L${width - 24.644} 55.5C${width - 27.323} 60.141 ${width - 32.275} 63 ${width - 37.634} 63L38.4032 63C33.0442 63 28.0923 60.141 25.4128 55.5L17.3299 41.5C14.6504 36.859 14.6504 31.141 17.3299 26.5L25.4128 12.5C28.0923 7.85897 33.0442 4.99999 38.4032 4.99999L${width - 37.634} 5C${width - 32.275} 5 ${width - 27.323} 7.85898 ${width - 24.644} 12.5L${width - 16.561} 26.5Z`} fill="white" />
              <path d={`M${width - 17.427} 41L${width - 25.51} 55C${width - 28.011} 59.3316 ${width - 32.633} 62 ${width - 37.634} 62L38.4032 62C33.4015 62 28.7797 59.3316 26.2788 55L18.1959 41C15.6951 36.6684 15.6951 31.3316 18.1959 27L26.2788 13C28.7797 8.66838 33.4015 5.99999 38.4032 5.99999L${width - 37.634} 6C${width - 32.633} 6 ${width - 28.011} 8.66838 ${width - 25.51} 13L${width - 17.427} 27C${width - 14.926} 31.3316 ${width - 14.926} 36.6684 ${width - 17.427} 41Z`} stroke="#A3C2D0" strokeWidth="2" />
            </g>
            <g
              id={`ButtonInnerSVG__small_${uniqID}`}
              filter={`url(#filter1_ddii_857_280_${uniqID})`}
            >
              <path d={`M${width - 26.334} 29.2377C${width - 24.547} 32.3317 ${width - 24.547} 36.1437 ${width - 26.334} 39.2377L${width - 32.107} 49.2376C${width - 33.893} 52.3316 ${width - 37.195} 54.2376 ${width - 40.767} 54.2376L41.0634 54.238C37.4907 54.238 34.1894 52.332 32.4031 49.238L26.6297 39.238C24.8433 36.144 24.8433 32.3321 26.6297 29.2381L32.4031 19.2381C34.1894 16.1441 37.4907 14.2381 41.0634 14.2381L${width - 40.767} 14.2378C${width - 37.195} 14.2378 ${width - 33.893} 16.1438 ${width - 32.107} 19.2378L${width - 26.334} 29.2377Z`} fill={`url(#paint0_linear_857_280_${uniqID})`} />
              <path d={`M${width - 27.2} 29.7377C${width - 25.592} 32.5223 ${width - 25.592} 35.9531 ${width - 27.2} 38.7377L${width - 32.973} 48.7376C${width - 34.581} 51.5222 ${width - 37.552} 53.2376 ${width - 40.767} 53.2376L41.0634 53.238C37.848 53.238 34.8768 51.5226 33.2691 48.738L27.4957 38.738C25.888 35.9534 25.888 32.5227 27.4957 29.7381L33.2692 19.7381C34.8768 16.9535 37.848 15.2381 41.0634 15.2381L${width - 40.767} 15.2378C${width - 37.552} 15.2378 ${width - 34.581} 16.9532 ${width - 32.973} 19.7378L${width - 27.2} 29.7377Z`} stroke={strokeColor} strokeWidth="2" />
            </g>
            {/* fix for Safari browser */}
            {/* Active */}
            <g id={`ButtonInnerSVG__Safari_small_${uniqID}`} filter={`url(#filter1_ddii_281_097_${uniqID})`}>
              <path d={`M${width - 26.334} 29.2377C${width - 24.547} 32.3317 ${width - 24.547} 36.1437 ${width - 26.334} 39.2377L${width - 32.107} 49.2376C${width - 33.893} 52.3316 ${width - 37.195} 54.2376 ${width - 40.767} 54.2376L41.0634 54.238C37.4907 54.238 34.1894 52.332 32.4031 49.238L26.6297 39.238C24.8433 36.144 24.8433 32.3321 26.6297 29.2381L32.4031 19.2381C34.1894 16.1441 37.4907 14.2381 41.0634 14.2381L${width - 40.767} 14.2378C${width - 37.195} 14.2378 ${width - 33.893} 16.1438 ${width - 32.107} 19.2378L${width - 26.334} 29.2377Z`} fill={`url(#paint0_linear_281_097_${uniqID})`} />
              <path d={`M${width - 27.2} 29.7377C${width - 25.592} 32.5223 ${width - 25.592} 35.9531 ${width - 27.2} 38.7377L${width - 32.973} 48.7376C${width - 34.581} 51.5222 ${width - 37.552} 53.2376 ${width - 40.767} 53.2376L41.0634 53.238C37.848 53.238 34.8768 51.5226 33.2691 48.738L27.4957 38.738C25.888 35.9534 25.888 32.5227 27.4957 29.7381L33.2692 19.7381C34.8768 16.9535 37.848 15.2381 41.0634 15.2381L${width - 40.767} 15.2378C${width - 37.552} 15.2378 ${width - 34.581} 16.9532 ${width - 32.973} 19.7378L${width - 27.2} 29.7377Z`} stroke={strokeColor} strokeWidth="2" />
            </g>
            {/* Hover */}
            <g id={`ButtonOpacitySVG_${uniqID}`} opacity="0.6" filter={`url(#filter2_f_326_868_${uniqID})`}>
              <path d={`M${width - 27.076} 34.9999C${width - 25.29} 38.0939 ${width - 25.29} 41.9059 ${width - 27.076} 44.9999L${width - 32.85} 54.9998C${width - 34.636} 58.0938 ${width - 37.937} 59.9998 ${width - 41.51} 59.9998L41.3207 60.0002C37.7481 60.0002 34.4468 58.0942 32.6604 55.0002L26.887 45.0002C25.1007 41.9062 25.1007 38.0943 26.887 35.0003L32.6605 25.0003C34.4468 21.9063 37.748 20.0003 41.3207 20.0003L${width - 41.51} 20C${width - 37.937} 20 ${width - 33.636} 21.906 ${width - 32.85} 25L${width - 27.076} 34.9999Z`} fill={hoverColor} />
            </g>
            {/* Text */}
            <g>
              {!isLoading && (
                <text id={`ButtonTextSVG_${uniqID}`} x="50%" y={yCoordText} dominantBaseline="middle" textAnchor="middle" fill="red" className={className.Title}>
                  {title}
                </text>
              )}
            </g>
          </g>
        )}
        {sizeButton === SizeButton.MEDIUM && (
          <g id={`ButtonSVGStyle_${uniqID}${disabled ? '_disabled' : ''}`} className={className.ActiveTransform} onClick={onClick}>
            <g filter={`url(#filter0_dii_857_280_${uniqID})`}>
              <path d={`M${width - 20.327} 43.0351C${width - 15.147} 52.0077 ${width - 15.147} 63.0625 ${width - 20.327} 72.0351L${width - 33.152} 94.249C${width - 38.333} 103.222 ${width - 47.906} 108.749 ${width - 58.267} 108.749L58.1608 108.749C47.8001 108.749 38.2264 103.222 33.046 94.249L20.2208 72.0351C15.0405 63.0625 15.0405 52.0077 20.2208 43.0351L33.046 20.8212C38.2264 11.8485 47.8001 6.32116 58.1608 6.32116L${width - 58.267} 6.32119C${width - 47.907} 6.32119 ${width - 38.333} 11.8486 ${width - 33.152} 20.8212L${width - 20.327} 43.0351Z`} stroke="#A3C2D0" strokeWidth="2" />
              <path d={`M${width - 19.461} 42.5351C${width - 14.102} 51.8171 ${width - 14.102} 63.2531 ${width - 19.461} 72.5351L${width - 32.286} 94.749C${width - 37.645} 104.031 ${width - 47.549} 109.749 ${width - 58.267} 109.749L58.1608 109.749C47.4428 109.749 37.539 104.031 32.18 94.749L19.3548 72.5351C13.9958 63.2531 13.9958 51.8171 19.3548 42.5351L32.18 20.3212C37.539 11.0391 47.4428 5.32116 58.1608 5.32116L${width - 58.267} 5.32119C${width - 47.549} 5.32119 ${width - 37.645} 11.0392 ${width - 32.286} 20.3212L${width - 19.461} 42.5351Z`} fill="white" />
            </g>
            <g className={styles.ButtonInnerSVG} filter={`url(#filter1_ddii_857_280_${uniqID})`}>
              <path d={`M${width - 30.234} 45.0351C${width - 25.768} 52.7701 ${width - 25.768} 62.3001 ${width - 30.234} 70.0351L${width - 40.035} 87.011C${width - 44.501} 94.746 ${width - 52.754} 99.511 ${width - 61.685} 99.511L61.9732 99.5114C53.0415 99.5114 44.7883 94.7464 40.3225 87.0113L30.5214 70.0353C26.0556 62.3003 26.0556 52.7703 30.5214 45.0353L40.3225 28.0594C44.7883 20.3244 53.0415 15.5594 61.9731 15.5594L${width - 61.685} 15.5592C${width - 52.754} 15.5592 ${width - 44.501} 20.3242 ${width - 40.035} 28.0592L${width - 30.234} 45.0351Z`} fill={`url(#paint0_linear_857_280_${uniqID})`} />
              <path d={`M${width - 31.533} 69.2851L${width - 41.334} 86.261C${width - 45.532} 93.5319 ${width - 53.39} 98.011 ${width - 61.685} 98.011L61.9732 98.0114C53.5774 98.0114 45.8194 93.5323 41.6215 86.2613L31.8204 69.2853C27.6226 62.0144 27.6226 53.0562 31.8205 45.7853L41.6215 28.8094C45.8194 21.5385 53.5774 17.0594 61.9731 17.0594L${width - 61.685} 17.0592C${width - 53.29} 17.0592 ${width - 45.532} 21.5383 ${width - 41.334} 28.8092L${width - 31.533} 45.7851C${width - 27.335} 53.056 ${width - 27.335} 62.0142 ${width - 31.533} 69.2851Z`} stroke={strokeColor} strokeWidth="3" />
            </g>
            {/* fix for Safari browser */}
            {/* Active */}
            <g id={`ButtonInnerSVG__Safari_${uniqID}`} filter={`url(#filter1_ddii_281_197_${uniqID})`}>
              <path d={`M${width - 30.234} 45.0351C${width - 25.768} 52.7701 ${width - 25.768} 62.3001 ${width - 30.234} 70.0351L${width - 40.035} 87.011C${width - 44.501} 94.746 ${width - 52.754} 99.511 ${width - 61.685} 99.511L61.9732 99.5114C53.0415 99.5114 44.7883 94.7464 40.3225 87.0113L30.5214 70.0353C26.0556 62.3003 26.0556 52.7703 30.5214 45.0353L40.3225 28.0594C44.7883 20.3244 53.0415 15.5594 61.9731 15.5594L${width - 61.685} 15.5592C${width - 52.754} 15.5592 ${width - 44.501} 20.3242 ${width - 40.035} 28.0592L${width - 30.234} 45.0351Z`} fill={`url(#paint0_linear_281_197_${uniqID})`} />
              <path d={`M${width - 31.533} 69.2851L${width - 41.334} 86.261C${width - 45.532} 93.5319 ${width - 53.39} 98.011 ${width - 61.685} 98.011L61.9732 98.0114C53.5774 98.0114 45.8194 93.5323 41.6215 86.2613L31.8204 69.2853C27.6226 62.0144 27.6226 53.0562 31.8205 45.7853L41.6215 28.8094C45.8194 21.5385 53.5774 17.0594 61.9731 17.0594L${width - 61.685} 17.0592C${width - 53.29} 17.0592 ${width - 45.532} 21.5383 ${width - 41.334} 28.8092L${width - 31.533} 45.7851C${width - 27.335} 53.056 ${width - 27.335} 62.0142 ${width - 31.533} 69.2851Z`} stroke={strokeColor} strokeWidth="3" />
            </g>

            {/* Hover */}
            <g id={`ButtonOpacitySVG_${uniqID}`} opacity="0.6" filter={`url(#filter2_f_281_002_${uniqID})`}>
              <path d={`M${width - 30.053} 50.1419C${width - 25.587} 57.8769 ${width - 25.587} 67.4069 ${width - 30.053} 75.1419L${width - 39.854} 92.1178C${width - 44.319} 99.8529 ${width - 52.573} 104.618 ${width - 61.504} 104.618L62.2786 104.618C53.3469 104.618 45.0937 99.8532 40.6279 92.1181L30.8268 75.1421C26.3611 67.4071 26.3611 57.8771 30.8269 50.1421L40.6279 33.1662C45.0937 25.4312 53.3469 20.6662 62.2785 20.6662L${width - 61.504} 20.666C${width - 52.573} 20.666 ${width - 44.319} 25.431 ${width - 39.854} 33.166L${width - 30.053} 50.1419Z`} fill={hoverColor} />
            </g>
            {/* Text */}
            <g>
              {!isLoading && (
                <text id={`ButtonTextSVG_${uniqID}`} x="50%" y={yCoordText} dominantBaseline="middle" textAnchor="middle" className={className.Title}>
                  {title}
                </text>
              )}
            </g>
          </g>
        )}
        <defs>
          {/* background color = white (basic) */}
          <filter id={`filter0_dii_857_280_${uniqID}`} x="0.335571" y="-0.678833" width={width - 0.778} height={heightView + 0.428} filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dy="10" />
            <feGaussianBlur stdDeviation="7.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_857_280" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_857_280" result="shape" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dy="-6" />
            <feGaussianBlur stdDeviation="3" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect2_innerShadow_857_280" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dy="-3" />
            <feGaussianBlur stdDeviation="3" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0" />
            <feBlend mode="normal" in2="effect2_innerShadow_857_280" result="effect3_innerShadow_857_280" />
          </filter>
          {/* background color = colorType */}
          <filter id={`filter1_ddii_857_280_${uniqID}`} x="19.1721" y="9.5592" width={width - 38.056} height={heightView - 33.048} filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="4" />
            <feComposite in2="hardAlpha" operator="out" />
            {colorType === ColorType.GREEN && <feColorMatrix type="matrix" values="0 0 0 0 0.156863 0 0 0 0 0.811765 0 0 0 0 0.121569 0 0 0 0.5 0" />}
            {colorType === ColorType.PURPLE && <feColorMatrix type="matrix" values="0 0 0 0 0.942245 0 0 0 0 0 0 0 0 0 0.95 0 0 0 0.3 0" />}
            {colorType === ColorType.ORANGE && <feColorMatrix type="matrix" values="0 0 0 0 0.870588 0 0 0 0 0.333333 0 0 0 0 0.0235294 0 0 0 0.43 0" />}
            {colorType === ColorType.RED && <feColorMatrix type="matrix" values="0 0 0 0 0.92549 0 0 0 0 0.0509804 0 0 0 0 0.239216 0 0 0 0.25 0" />}
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_857_280" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset />
            <feGaussianBlur stdDeviation="3" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="effect1_dropShadow_857_280" result="effect2_dropShadow_857_280" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_857_280" result="shape" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            {colorType !== ColorType.RED && <>
            <feOffset dy="10" />
            <feGaussianBlur stdDeviation="5" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            {colorType === ColorType.GREEN && <feColorMatrix type="matrix" values="0 0 0 0 0.556298 0 0 0 0 1 0 0 0 0 0.0916666 0 0 0 1 0" />}
            {colorType === ColorType.PURPLE && <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.0431373 0 0 0 0 0.980392 0 0 0 1 0" />}
            {colorType === ColorType.ORANGE && <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.84 0 0 0 0 0 0 0 0 1 0" />}
            <feBlend mode="normal" in2="shape" result="effect3_innerShadow_857_280" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            </>}
            <feOffset dy={colorType === ColorType.RED ? "4" : "-7"} />
            <feGaussianBlur stdDeviation="3" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            {colorType === ColorType.GREEN && <feColorMatrix type="matrix" values="0 0 0 0 0.269403 0 0 0 0 0.766667 0 0 0 0 0.156528 0 0 0 1 0" />}
            {colorType === ColorType.PURPLE && <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.0431373 0 0 0 0 0.980392 0 0 0 0.4 0" />}
            {colorType === ColorType.ORANGE && <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.827451 0 0 0 0 0.211765 0 0 0 0.5 0" />}
            {colorType === ColorType.RED && <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.585766 0 0 0 0 0.495833 0 0 0 1 0" />}
            <feBlend mode="normal" in2={colorType === ColorType.RED ? "shape" : "effect3_innerShadow_857_280"} result="effect4_innerShadow_857_280" />
          </filter>
          {/* Hover */}
          <filter id={`filter2_f_281_002_${uniqID}`} x="0.477539" y="0.666016" width={width + 5.943} height="123.952" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feGaussianBlur stdDeviation={colorType === ColorType.RED ? "5" : "10"} result="effect1_foregroundBlur_281_002" />
          </filter>
          <filter id={`filter2_f_326_868_${uniqID}`} x="5.54736" y="0" width={width - 11.3} height="80.0002" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feGaussianBlur stdDeviation="10" result="effect1_foregroundBlur_326_868" />
          </filter>
          <linearGradient
            id={`paint0_linear_857_280_${uniqID}`}
            x1={colorType === ColorType.RED ? "35.2528" : "72.4568"}
            y1={colorType === ColorType.RED ? "50.2472" : "97.8125"}
            x2={colorType === ColorType.RED ? "35.2528" : "72.4568"}
            y2={colorType === ColorType.RED ? "11.0993" : "18.6229"}
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={darkColor} />
            <stop offset="1" stopColor={lightColor} />
          </linearGradient>

          {/* Active Block */}
          {/* Medium size */}
          <filter id={`filter1_ddii_281_197_${uniqID}`} x="19.4775" y="10.094" width={width - 38.057} height="101.952" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dy={colorType === ColorType.RED ? "3" : "4"} />
            <feGaussianBlur stdDeviation={colorType === ColorType.RED ? "2" : "4"} />
            <feComposite in2="hardAlpha" operator="out" />
            {colorType === ColorType.ORANGE && <feColorMatrix type="matrix" values="0 0 0 0 0.870588 0 0 0 0 0.333333 0 0 0 0 0.0235294 0 0 0 0.43 0" />}
            {colorType === ColorType.GREEN && <feColorMatrix type="matrix" values="0 0 0 0 0.156863 0 0 0 0 0.811765 0 0 0 0 0.121569 0 0 0 0.5 0" />}
            {colorType === ColorType.PURPLE && <feColorMatrix type="matrix" values="0 0 0 0 0.942245 0 0 0 0 0 0 0 0 0 0.95 0 0 0 0.3 0" />}
            {colorType === ColorType.RED && <feColorMatrix type="matrix" values="0 0 0 0 0.92549 0 0 0 0 0.0509804 0 0 0 0 0.239216 0 0 0 0.25 0" />}
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_281_197" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset />
            <feGaussianBlur stdDeviation="3" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="effect1_dropShadow_281_197" result="effect2_dropShadow_281_197" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_281_197" result="shape" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            {colorType !== ColorType.RED && <>
              <feOffset dy="10" />
              <feGaussianBlur stdDeviation="5" />
              <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
              {colorType === ColorType.ORANGE && <feColorMatrix type="matrix" values="0 0 0 0 0.620833 0 0 0 0 0.231285 0 0 0 0 0.0103472 0 0 0 1 0" />}
              {colorType === ColorType.GREEN && <feColorMatrix type="matrix" values="0 0 0 0 0.0839931 0 0 0 0 0.491667 0 0 0 0 0.114826 0 0 0 1 0" />}
              {colorType === ColorType.PURPLE && <feColorMatrix type="matrix" values="0 0 0 0 0.450639 0 0 0 0 0.0262153 0 0 0 0 0.629167 0 0 0 1 0" />}
              <feBlend mode="normal" in2="shape" result="effect3_innerShadow_281_197" />
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            </>}
            <feOffset dy={colorType === ColorType.RED ? "4" : "-5"} />
            <feGaussianBlur stdDeviation={colorType === ColorType.RED ? "2" : "5"} />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            {colorType === ColorType.ORANGE && <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.827451 0 0 0 0 0.211765 0 0 0 0.5 0" />}
            {colorType === ColorType.GREEN && <feColorMatrix type="matrix" values="0 0 0 0 0.269403 0 0 0 0 0.766667 0 0 0 0 0.156528 0 0 0 1 0" />}
            {colorType === ColorType.PURPLE && <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.0431373 0 0 0 0 0.980392 0 0 0 0.4 0" />}
            {colorType === ColorType.RED && <feColorMatrix type="matrix" values="0 0 0 0 0.8 0 0 0 0 0.103333 0 0 0 0 0.222963 0 0 0 1 0"/>}
            <feBlend mode="normal" in2={colorType === ColorType.RED ? "shape" : "effect3_innerShadow_281_197"} result="effect4_innerShadow_281_197" />
          </filter>
          <linearGradient 
            id={`paint0_linear_281_197_${uniqID}`} 
            x1="72.7623" 
            y1="98.3473" 
            x2="72.7622" 
            y2="19.1577" 
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={darkColor} />
            {/* Style: add light color in area pushed button */}
            <stop offset="1" stopColor={lightActiveColor} />
          </linearGradient>
          <linearGradient
            id={`paint0_linear_281_097_${uniqID}`}
            x1="51.6784"
            y1="53.4286"
            x2="51.6784"
            y2="15.6976"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={darkColor} />
            {/* Style: add light color in area pushed button */}
            <stop offset="1" stopColor={lightActiveColor} />
          </linearGradient>
          <style>
            {`
            #ButtonSVGStyle_${uniqID} #ButtonOpacitySVG_${uniqID} {
              display: none;
            }
            #ButtonSVGStyle_${uniqID}_disabled #ButtonOpacitySVG_${uniqID} {
              display: none;
            }
            #ButtonSVGStyle_${uniqID} #ButtonInnerSVG__Safari_${uniqID} {
              display: none;
            }
            #ButtonSVGStyle_${uniqID} #ButtonInnerSVG__Safari_small_${uniqID} {
              display: none;
            }
            #ButtonSVGStyle_${uniqID}_disabled #ButtonInnerSVG__Safari_${uniqID} {
              display: none;
            }
            #ButtonSVGStyle_${uniqID}_disabled #ButtonInnerSVG__Safari_small_${uniqID} {
              display: none;
            }
            #ButtonSVGStyle_${uniqID}_disabled #ButtonTextSVG_${uniqID} {
              opacity: 0.5;
            }
            @media (hover: hover) {
              #ButtonSVGStyle_${uniqID}:hover {
                cursor: pointer;
              }
              #ButtonSVGStyle_${uniqID}:hover #ButtonOpacitySVG_${uniqID} {
                display: block;
              }
            }

            #ButtonSVGStyle_${uniqID}:active {
              transform: scale(${valueScaleAnimation})
            }

            #ButtonSVGStyle_${uniqID}:active #ButtonOpacitySVG_${uniqID} {
              display: none;
            }
            #ButtonSVGStyle_${uniqID}:active #ButtonInnerSVG_${uniqID} {
              filter: url(#filter1_ddii_281_197_${uniqID});
              -webkit-filter: url(#filter1_ddii_281_197_${uniqID});
            }
            #ButtonSVGStyle_${uniqID}:active #ButtonInnerSVG_${uniqID} path {
              fill: url(#paint0_linear_281_197_${uniqID});
            }
            #ButtonSVGStyle_${uniqID}:active #ButtonInnerSVG__Safari_${uniqID} {
              display: block;
            }

            #ButtonSVGStyle_${uniqID}:active #ButtonInnerSVG__small_${uniqID} {
              filter: url(#filter1_ddii_281_197_${uniqID});
              -webkit-filter: url(#filter1_ddii_281_197_${uniqID});
            }
            #ButtonSVGStyle_${uniqID}:active #ButtonInnerSVG__small_${uniqID} path {
              fill: url(#paint0_linear_281_097_${uniqID});
            }
            #ButtonSVGStyle_${uniqID}:active #ButtonInnerSVG__Safari_small_${uniqID} {
              display: block;
            }
          `}
          </style>
        </defs>
      </svg>
    </div>
  );
};

export const Button = ({
  width,
  height,
  title,
  size,
  onClick,
  colorType,
  isScaleTransform,
  uniqID,
  valueScaleAnimation,
  classnames,
  disabled,
  isPicSoon,
  isLoading,
  widthMobileDifference,
}) => {
  const [widthButton, setWidthButton] = useState(0);
  const textRef = useRef();
  const stores = useStores();

  useEffect(() => {
    if (widthButton !== 0) {
      if (size === SizeButton.MEDIUM) {
        let changedSizeButton = textRef.current.offsetWidth + 100;
        if (changedSizeButton <= width) {
          changedSizeButton = width;
        }
        setWidthButton(changedSizeButton);
      } else {
        let changedSizeButton = textRef.current.offsetWidth + 60;
        if (changedSizeButton <= width) {
          changedSizeButton = width;
        }
        setWidthButton(changedSizeButton);
      }
    }
  }, [title]);

  useEffect(() => {
    if (!textRef) return;
    if (size === SizeButton.MEDIUM) {
      let changedSizeButton = textRef.current.offsetWidth + 100;
      if (changedSizeButton <= width) {
        changedSizeButton = width;
      }
      setWidthButton(changedSizeButton);
    } else {
      let changedSizeButton = textRef.current.offsetWidth + 10;
      if (changedSizeButton <= width) {
        changedSizeButton = width;
      }
      setWidthButton(changedSizeButton);
    }
  }, [textRef]);

  // if (widthButton === 0) {
  //   return (
  //     <>
  //       <p ref={textRef} className={styles.Title}>
  //         {title}
  //       </p>
  //     </>
  //   );
  // }
  return (
    <div 
      className={classNames(styles.Container, classnames.Container)} 
      style={{ width: widthButton }}
    >
      <p ref={textRef} className={classNames(styles.Title, styles[`Title__${size}`], styles.TitleTransparent)}>
        {title}
      </p>
      {widthButton !== 0 && (
        <ButtonSVG
          // width={width}
          // width={!!width ? width : widthButton}
          width={!!widthMobileDifference ? (widthButton+widthMobileDifference) : widthButton }
          height={!!height ? height+DIFFERENCE_ACTUAL_AND_SVG_HEIGHT : size === SizeButton.MEDIUM ? 135 : 88}
          sizeButton={size}
          title={title}
          onClick={() => {
            // console.log('tapped');
            onClick();
          }}
          className={{
            Container: styles.OuterContainer,
            Title: classNames(styles.Title, styles[`Title__${size}`], classnames.Title),
            ActiveTransform: isScaleTransform && styles.ActiveTransform,
          }}
          colorType={colorType}
          uniqID={uniqID}
          valueScaleAnimation={isScaleTransform ? valueScaleAnimation : 1}
          disabled={disabled}
          isLoading={isLoading}
        />
      )}
      {isPicSoon && <img onLoad={() => stores.increaseDownloadedImageCounter(SoonImage)} onError={() => console.log('Error with image loading')} src={SoonImage} className={classNames(styles.SoonImage, styles[`SoonImage__${size}`])} alt="soon_image" />}
      {isLoading && <div className={classNames(styles.ProgressIndicator, styles[`ProgressIndicator__${size}`])} />}
    </div>
  );
};

Button.propTypes = {
  width: PropTypes.number,
  title: PropTypes.string,
  size: PropTypes.oneOf(Object.keys(SizeButton)),
  colorType: PropTypes.oneOf(Object.keys(ColorType)),
  onClick: PropTypes.func,
  isScaleTransform: PropTypes.bool,
  uniqID: PropTypes.oneOfType([PropTypes.string]),
  valueScaleAnimation: PropTypes.number,
  classnames: PropTypes.object,
  disabled: PropTypes.bool,
  isPicSoon: PropTypes.bool,
  isLoading: PropTypes.bool,
  height: PropTypes.number,
  widthMobileDifference: PropTypes.number
};

Button.defaultProps = {
  width: 290,
  colorType: ColorType.GREEN,
  title: 'I love SVG',
  size: SizeButton.MEDIUM,
  onClick: () => {},
  isScaleTransform: true,
  uniqID: 'uniq_id',
  valueScaleAnimation: 1,
  classnames: {},
  disabled: false,
  isPicSoon: false,
  isLoading: false,
  height: undefined,
  widthMobileDifference: undefined
};

export default {
  Button,
  ColorType,
  SizeButton,
  UNIQ_ID_BUTTON,
};
