import React from 'react';
import classnames from 'classnames';
import { observer } from 'mobx-react';
import P2P from '@src/assets/svg/P2P.svg';
import EcosystemExchange from '@src/assets/svg/EcosystemExchange.svg';
import Exchange from '@src/assets/svg/Exchange.svg';
import Summary from '@src/assets/svg/Summary.svg';
import Ecosystem from '@src/assets/svg/Ecosystem.svg';
import Mainnet from '@src/assets/svg/Mainnet.svg';
import BearCard from '@src/assets/svg/BearCard.svg';
import BearCardSmall from '@src/assets/svg/BearCardSmall.svg';

import { useStores } from '@src/stores';
import styles from './cards.module.scss';

const CardType = {
  Black: '_black',
  Gray: '_gray',
  Yellow: '_yellow',
};

export const CardSize = {
  Big: 'BigSize',
  Medium: 'MediumSize',
  Small: 'SmallSize',
  EconomyCoinSize: 'EconomyCoinSize',
};

const Card = ({
  title,
  secondTitle = '',
  thirdTitle = '',
  type,
  icon,
  size,
  classNames = {},
  isClickable,
}) => {
  return (
    <div
      className={classnames(
        styles.Card,
        styles[type],
        styles[size || CardSize.Medium],
        isClickable && styles.Clickable,
        classNames,
        classNames.Container,
      )}
    >
      {/* <div className={styles.Icon}> */}
      <img
        src={icon}
        alt="icon"
        loading="lazy"
        className={classnames(
          (icon === Mainnet && size === 'SizeSmall') ||
            (icon === Mainnet && size === 'SmallSize') ||
            (icon === Mainnet && size === 'EconomyCoinSize')
            ? styles.MainnetIcon
            : styles.Icon,
          icon === Mainnet && size === 'SmallSize' ? styles.TopZero : null,
          icon === BearCardSmall || icon === BearCard ? styles.BearCard : null,
        )}
      />
      {/* </div> */}
      {/* <div className={styles.Icon}>{icon}</div> */}
      <div
        className={classnames(styles.Text, classNames.Title)}
        dangerouslySetInnerHTML={{ __html: title }}
      />
      {secondTitle && (
        <div
          className={classnames(styles.BurnDescription, classNames.SecondTitle)}
          dangerouslySetInnerHTML={{ __html: secondTitle }}
        />
      )}
      {thirdTitle && (
        <div
          className={classnames(styles.ThirdDescription, classNames.ThirdTitle)}
          dangerouslySetInnerHTML={{ __html: thirdTitle }}
        />
      )}
    </div>
  );
};

export const P2PCard = observer(({ size = 'MediumSize' }) => {
  const stores = useStores();
  // eslint-disable-next-line no-unused-vars
  const _ = stores.language;

  return (
    <Card
      title={stores.translate('economy_p2p_trading')}
      type={CardType.Black}
      icon={P2P}
      // icon={<P2P />}
      size={size}
    />
  );
});

export const EcosystemExchangeCard = observer(({ size = 'MediumSize' }) => {
  const stores = useStores();
  // eslint-disable-next-line no-unused-vars
  const _ = stores.language;
  return (
    <Card
      title={stores.translate('economy_ecosystem_exchange')}
      type={CardType.Gray}
      icon={EcosystemExchange}
      // icon={<EcosystemExchange />}
      size={size}
    />
  );
});

export const ExchangeCard = observer(({ size = 'MediumSize' }) => {
  const stores = useStores();
  // eslint-disable-next-line no-unused-vars
  const _ = stores.language;

  return (
    <Card
      title={stores.translate('economy_exchange')}
      type={CardType.Yellow}
      icon={Exchange}
      // icon={<Exchange />}
      size={size}
    />
  );
});

export const SummaryCard = observer(({ size = 'BigSize', classNames }) => {
  const stores = useStores();
  // eslint-disable-next-line no-unused-vars
  const _ = stores.language;

  return (
    <Card
      title={stores.translate('article_summary_of_2021_title')}
      type={CardType.Black}
      icon={Summary}
      // icon={<Summary />}
      size={size || CardSize.Big}
      classNames={[styles.Clickable, classNames]}
    />
  );
});

export const EcosystemCard = observer(({ size, classNames, isMobile = false }) => {
  const stores = useStores();
  // eslint-disable-next-line no-unused-vars
  const _ = stores.language;
  // const title = (
  //   <div>
  //     Сжигать или
  //     <br />
  //     холдить монеты?
  //   </div>
  // );

  return (
    <Card
      title={
        isMobile
          ? stores.translate('article_ecosystem_short_mobile_title')
          : stores.translate('article_ecosystem_short_title')
      }
      type={CardType.Gray}
      icon={Ecosystem}
      // icon={<Ecosystem />}
      size={size || CardSize.Big}
      classNames={[styles.Clickable, classNames]}
    />
  );
});

export const MainnetCard = observer(({ size, classNames }) => {
  const stores = useStores();
  // eslint-disable-next-line no-unused-vars
  const _ = stores.language;

  // const title = (
  //   <div>
  //     ТОП-7 сервисов
  //     <br />
  //     из реальной жизни
  //   </div>
  // );

  return (
    <Card
      title={stores.translate('article_mainnet_title')}
      type={CardType.Yellow}
      icon={Mainnet}
      // icon={<Mainnet />}
      size={size || CardSize.Big}
      classNames={[styles.Clickable, classNames]}
    />
  );
});

export const HoneywoodCard = observer(({ size, classNames, isMobile }) => {
  const stores = useStores();
  // eslint-disable-next-line no-unused-vars
  const _ = stores.language;

  return (
    <Card
      title={stores.translate('article_honeywood_mobile_title')}
      secondTitle={stores.translate('presale')}
      thirdTitle={stores.translate('release_presale')}
      type={CardType.Gray}
      icon={isMobile ? BearCardSmall : BearCard}
      size={size || CardSize.Big}
      isClickable
      classNames={{
        ...classNames,
        Title: [styles.WhiteTitle, classNames.Title],
        SecondTitle: [styles.SecondHoneyWoodTitle, classNames.SecondHoneyWoodTitle],
        ThirdTitle: [styles.ThirdHoneyWoodTitle, classNames.ThirdHoneyWoodTitle],
      }}
    />
  );
});
