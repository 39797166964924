/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';
import { gtagEvent } from '@utils/common';
import { GOOGLE_ANALYTICS_CONSTANTS } from '@src/constants/googleAnalyticsEvents';
import { useStores } from '@src/stores';
import { ArticlePage, ArticleName } from '../components/ArticlePage';

const SummaryArticle = observer(() => {
  const stores = useStores();
  const _ = stores.language;

  /* useEffect for Google Analytics */
  const location = useLocation();
  useEffect(() => {
    gtagEvent(GOOGLE_ANALYTICS_CONSTANTS.VISIT_SUMMARY_ARTICLE);
    // window.gtag('event', 'visitToSummaryArticle', {
    //   page_title: location.pathname,
    // });
    // console.log('visitToSummaryArticle: ', location.pathname);
  }, [location]);

  return (
    <ArticlePage
      article={ArticleName.monetaEcosystem}
      title={stores.translate('article_summary_of_2021_title')}
      text={stores.translate('article_summary_of_2021_text')}
    />
  );
});

export default SummaryArticle;
