import React, { useState } from 'react';
import { cn } from '@bem-react/classname';
import './styles.scss';
import { classnames } from '@bem-react/classnames';

const inputCN = cn('Input');

const Input = (
  {
    register = () => {},
    name,
    value,
    placeholder = '',
    type = 'text',
    label = '',
    error = '',
    canError = false,
    isButtonSecretShow = false,
    readOnly = false,
    isFewInputsRow = false,
    classNames = {},
    onChange = () => {},
    ...props
  },
  ref,
) => {
  const [isSecretText, setSecretText] = useState(isButtonSecretShow);

  const onShowSecretText = () => {
    setSecretText(!isSecretText);
  };

  return (
    <div
      className={classnames(
        inputCN('Container', { canError, isFewInputsRow }),
        classNames.Container,
      )}
    >
      {!!label && <p className={inputCN('Label')}>{label}</p>}
      <div
        className={classnames(
          inputCN('InputContainer', { canError, readOnly }),
          classNames.InputContainer,
        )}
      >
        <input
          ref={(e) => {
            if (ref) {
              ref.current = e;
              register(e);
            }
          }}
          className={classnames(inputCN('Input'), classNames.Input)}
          placeholder={placeholder}
          type={isSecretText ? 'password' : type}
          value={value}
          readOnly={readOnly}
          onChange={onChange}
          {...register(name)}
          {...props}
        />
        {isButtonSecretShow && (
          <button
            type="button"
            className={classnames(inputCN('InputButtonShow'), classNames.InputButtonShow)}
            onClick={onShowSecretText}
          >
            <p>SHOW</p>
          </button>
        )}
      </div>
      {!!error && (
        <p className={classnames(inputCN('Error'), classNames.Error)}>{error?.message}</p>
      )}
    </div>
  );
};

export default React.forwardRef(Input);
