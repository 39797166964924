export const gtagEvent = (event) => {
  if (process.env.REACT_APP_STAGE !== 'production') {
    return () => {};
  }
  return window.gtag('event', event, {
    PATHNAME: window.location.pathname,
  });
};

export default {
  gtagEvent
};
