import React, { useLayoutEffect, useState } from 'react';
import { LanguageContext } from '@src/contexts/LanguageContext';
import { DEFAULT_LANGUAGE } from '@src/constants/language';

const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState('Eng');

  useLayoutEffect(() => {
    const lastLang = window.localStorage.getItem('language');

    if (!lastLang || lastLang === 'en' || lastLang === 'ru') {
      setLanguage(DEFAULT_LANGUAGE);
      window.localStorage.setItem('language', DEFAULT_LANGUAGE);
    } else {
      setLanguage(lastLang);
    }
  }, []);

  const toggleLanguage = () => {
    const newLanguage = language === 'en' ? 'ru' : 'en';
    setLanguage(newLanguage);
    window.localStorage.setItem('language', newLanguage);
  };

  return (
    <LanguageContext.Provider value={{ language, toggleLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export default LanguageProvider;
