import React from 'react';
import { cn } from '@bem-react/classname';
import './styles.scss';

const spinnerComponentCN = cn('SpinnerComponent');

const Spinner = () => {
  return (
    <div className={spinnerComponentCN('Wrapper')}>
      <div className={spinnerComponentCN('Div')} />
    </div>
  );
};

export default Spinner;
