import React, { useState, useEffect } from 'react';
import Layout from '@src/components/Layout';
import { useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useStores } from '@src/stores';
import { gtagEvent } from '@utils/common';
import { GOOGLE_ANALYTICS_CONSTANTS } from '@src/constants/googleAnalyticsEvents';
import ServicesSection from './components/ServicesSection';
import TransformationSection from './components/TransformationSection';
import BlockchainGameSection from './components/BlockchainGameSection';
import PrincipleSection from './components/PrincipleSection';
import CoinSection from './components/CoinSection';
import AcquaintanceSection from './components/AcquaintanceSection';
import InfoSection from './components/InfoSection';
import InvestSection from './components/InvestSection';
import Modal from './components/Modal';
import PopUp from './components/PopUp';
import EconomySection from './components/EconomySection';
import RoadMapSection from './components/RoadMapSection';
import HoneywoodSection from './components/HoneywoodSection';

const DesktopHomePage = observer(() => {
  const [isOpen, setIsOpen] = useState(false);
  const [isPopUpOpen, setPopUpOpen] = useState(false);
  const stores = useStores();
  // eslint-disable-next-line no-unused-vars
  const _ = stores.language;

  /* useEffect for Google Analytics */
  const location = useLocation();
  useEffect(() => {
    gtagEvent(GOOGLE_ANALYTICS_CONSTANTS.VISIT_MAIN);
  }, [location]);

  const open = () => {
    /* Dynamically lock scrolling background */
    document.body.style.overflow = 'hidden';
    setIsOpen(true);
  };
  
  const close = () => {
    /* Dynamically unlock scrolling background */
    document.body.style.overflow = 'visible';
    setIsOpen(false);
  };
  
  const closePopUp = () => {
    /* Dynamically unlock scrolling background */
    document.body.style.overflow = 'visible';
    setPopUpOpen(false);
  };
  
  const openPopUp = () => {
    /* Dynamically lock scrolling background */
    document.body.style.overflow = 'hidden';
    setPopUpOpen(true);
    setTimeout(closePopUp, 3000);
  };
  

  return (
    <Layout>
      <ServicesSection open={open} stores={stores} />
      <TransformationSection stores={stores} />
      {/* <BlockchainGameSection stores={stores} /> */}
      {/* <HoneywoodSection stores={stores} /> */}
      {/* <AcquaintanceSection stores={stores} /> */}
      {/* <InfoSection stores={stores} /> */}
      {/* <PrincipleSection stores={stores} /> */}
      {/* <CoinSection open={open} stores={stores} /> */}
      {/* <EconomySection stores={stores} /> */}
      {/* <RoadMapSection stores={stores} /> */}
      {/* <InvestSection open={open} stores={stores} /> */}
      <Modal isOpen={isOpen} close={close} stores={stores} openPopUp={openPopUp} />
      <PopUp isPopUpOpen={isPopUpOpen} closePopUp={closePopUp} stores={stores} />
    </Layout>
  );
});

export default DesktopHomePage;
