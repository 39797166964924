import React from 'react';
import classnames from 'classnames';
import Button from '@src/components/Button';

import styles from './cookiesPopUp.module.scss';

const CookiesPopUp = ({ html = '', isCookiesPopUpOpen, classNames = {}, onClick = () => {} }) => {
  return (
    isCookiesPopUpOpen && (
      <div className={classnames(styles.CookiesPopUpContainer, classNames.CookiesPopUpContainer)}>
        <div className={classnames(styles.CookiesPopUpText, classNames.CookiesPopUpText)}>
          {html}
        </div>
        <div className={classnames(styles.ButtonContainer, classNames.ButtonContainer)}>
          <Button
            onClick={onClick}
            classNames={{ Container: styles.Button, ButtonText: styles.ButtonText }}
            isMedium
            text="Accept"
          />
        </div>
      </div>
    )
  );
};

export default CookiesPopUp;
