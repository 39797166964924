import React from 'react';
import Phone from '@src/assets/images/Phone.png';
import styles from './transformation.module.scss';

const TransformationSection = ({ stores }) => {
  return (
    <div className={styles.TransformationContainer}>
      <div className={styles.TransformationContent}>
        <div className={styles.TransformationTitle}>
          {stores.translate('home_transformation_title')}
        </div>
        <p className={styles.TransformationText}>
          {stores.translate('home_transformation_text')[0]}
        </p>
        <p className={styles.TransformationText}>
          {stores.translate('home_transformation_text')[1]}
        </p>
        <div className={styles.TransformationMobileBlock}>
          <img src={Phone} alt="Phone" loading="lazy" className={styles.TransformationMobile} />
        </div>
      </div>
    </div>
  );
};

export default TransformationSection;
