import React from 'react';
import { useMediaQuery } from '@src/helpers/media';
import MobileHoneyWoodPage from '@src/pages/HoneyWoodPage/components/MobileHoneyWoodPage';
import DesktopHoneyWoodPage from '@src/pages/HoneyWoodPage/components/DesktopHoneyWoodPage';
import { useStores } from '@src/stores';

const HoneyWoodPage = () => {
  const stores = useStores();
  const isMobileSize = useMediaQuery('(max-width: 570px)');

  // return isMobileSize ? <MobileHoneyWoodPage /> : <DesktopHoneyWoodPage />;
  return stores.browserInfo.isMobile ? <MobileHoneyWoodPage /> : <DesktopHoneyWoodPage />;
};

export default HoneyWoodPage;
