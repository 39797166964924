import React from 'react';
import { cn } from '@bem-react/classname';
import { classnames } from '@bem-react/classnames';
import CheckBoxSelected from '@src/assets/svg/check_box_selected.svg';
import CheckBoxNotSelected from '@src/assets/svg/check_box_not_selected.svg';
import './styles.scss';

const checkBoxCN = cn('CheckBoxComponent');

const CheckBox = ({
  selected = false,
  onChange = () => {},
  title = '',
  // value = false,
  classNames = {},
  disabled = false,
}) => {
  return (
    <div
      className={classnames(checkBoxCN('Container', { disabled }), classNames.Container)}
      onClick={() => {
        if (!disabled) onChange(!selected);
      }}
      role="checkbox"
      aria-checked="false"
      tabIndex="0"
      onKeyPress={() => {
        if (!disabled) onChange(!selected);
      }}
    >
      {/* <div className={classnames(checkBoxCN('OuterCircle', { selected }), classNames.OuterCircle)}>
        <div
          className={classnames(
            checkBoxCN('InnerCircle', { selectedCircle: selected }),
            classNames.InnerCircle,
          )}
        />
      </div> */}
      {selected ? (
        <img src={CheckBoxSelected} alt="CheckBoxSelected" loading="lazy" />
      ) : (
        <img src={CheckBoxNotSelected} alt="CheckBoxNotSelected" loading="lazy" />
      )}

      <div className={classnames(checkBoxCN('Title', { selected, disabled }), classNames.Title)}>
        {title}
      </div>
    </div>
  );
};

export default CheckBox;
