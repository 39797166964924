import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { debounce } from 'lodash';
import { sanitize } from 'dompurify';
import { gtagEvent } from '@utils/common';
import { GOOGLE_ANALYTICS_CONSTANTS } from '@src/constants/googleAnalyticsEvents';
import URLS from '@src/constants/urls';
import { useMediaQuery } from '@src/helpers/media';
import Input from '@src/components/Input';
import classnames from 'classnames';
import { observer } from 'mobx-react';
import { useStores } from '@src/stores';
import Layout from '@src/components/Layout';
import Button from '@src/components/Button';
import {
  Button as HoneyButton,
  ColorType,
  SizeButton,
  UNIQ_ID_BUTTON,
} from '@src/components/HoneyButton';

import MobileSolarSystem from '@src/assets/svg/MobileSolarSystem.svg';
import MobileCore from '@src/assets/svg/MobileCore.svg';
import Core from '@src/assets/svg/Core.svg';
import TaxiAfter from '@src/assets/svg/TaxiAfter.svg';
import TaxiBefore from '@src/assets/svg/TaxiBefore.svg';
import Close from '@src/assets/svg/Close.svg';
import HoneyWoodLandingMobile from '@src/assets/svg/HoneyWoodLandingMobile.svg';
import MobilePhone from '@src/assets/images/MobilePhone.png';
import BlockchainGameImage from '@src/assets/images/BlockchainGamePage/blockchain_game_image_mobile.png';
import BeeLeft from '@src/assets/images/BlockchainGamePage/bee_left.png';
import BeeRight from '@src/assets/images/BlockchainGamePage/bee_right.png';
import HoneywoodLogo from '@src/assets/images/BlockchainGamePage/honeywood_logo.png';

import {
  EcosystemCard,
  ExchangeCard,
  EcosystemExchangeCard,
  P2PCard,
  MainnetCard,
  SummaryCard,
  HoneywoodCard,
} from '@src/components/Cards';
import MobileRoadMapSection from './components/MobileRoadMapSection';
import MobilePopUp from './components/MobilePopUp';
import styles from './mobileHome.module.scss';

/* For prevent scrolling to page's bottom in Modal mode */
let yOffset = 0;

const MobileHomePage = observer(() => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isPopUpOpen, setPopUpOpen] = useState(false);
  const stores = useStores();
  const isAndroid = /(android)/i.test(navigator.userAgent);
  // eslint-disable-next-line no-unused-vars
  const _ = stores.language;

  /* For prevent scrolling to page's bottom in Modal mode */
  useEffect(() => {
    if (isAndroid && !isOpen) {
      window.scrollTo(0, yOffset);
    } else if (isAndroid && isOpen) {
      yOffset = window.pageYOffset;
      window.scrollTo(0, 0);
    }
  }, [isOpen]);

  /* useEffect for Google Analytics */
  const location = useLocation();
  useEffect(() => {
    gtagEvent(GOOGLE_ANALYTICS_CONSTANTS.VISIT_MAIN);
  }, [location]);

  const open = () => {
    /* Dynamically lock scrolling background */
    document.body.style.overflow = 'hidden';
    setIsOpen(true);
  };

  const close = () => {
    /* Dynamically UNlock scrolling background */
    document.body.style.overflow = 'visible';
    setIsOpen(false);
  };

  const closePopUp = () => {
    /* Dynamically unlock scrolling background */
    document.body.style.overflow = 'visible';
    setPopUpOpen(false);
  };

  const openPopUp = () => {
    /* Dynamically lock scrolling background */
    document.body.style.overflow = 'hidden';
    setPopUpOpen(true);
    setTimeout(closePopUp, 3000);
  };

  return (
    <Layout>
      <Services open={open} stores={stores} />
      <Transformation stores={stores} />
      {/* <BlockchainGame stores={stores} /> */}
      {/* <HoneyWood stores={stores} /> */}
      {/* <Acquaintance stores={stores} /> */}
      {/* <Info stores={stores} /> */}
      {/* <Principle stores={stores} /> */}
      {/* <Coin open={open} stores={stores} /> */}
      {/* <Economy stores={stores} /> */}
      {/* <MobileRoadMapSection stores={stores} /> */}
      {/* <InvestTimeAndMoney open={open} stores={stores} /> */}
      <Modal isOpen={isOpen} close={close} stores={stores} openPopUp={openPopUp} />
      <MobilePopUp isPopUpOpen={isPopUpOpen} closePopUp={closePopUp} stores={stores} />
    </Layout>
  );
});

const debounced = debounce(() => {
  gtagEvent(GOOGLE_ANALYTICS_CONSTANTS.SLIDE_INVEST);
}, 1000);

const min = 500;
const max = 1000000;

const InvestInput = ({ value, onInput, stores }) => {
  const onChange = (event) => {
    onInput(event.target.value);
  };

  return (
    <>
      <p className={styles.ModalInvestText}>{stores.translate('proposal_how_much_invest')}</p>
      <div className={classnames(styles.ModalInvestSum, styles.ModalInvestInput)}>
        {`${value === '1000000' ? '>1000000' : value} USD`}
      </div>
      <input
        className={styles.ModalInvestSlider}
        type="range"
        min={min}
        max={max}
        value={value}
        onChange={(event) => {
          onChange(event);
          debounced();
        }}
        step="500"
      />
    </>
  );
};

export const Modal = ({ isOpen, close, stores, openPopUp }) => {
  const [usd, setUsd] = useState(500);
  const [isLoading, setLoading] = useState(false);

  /* Logic for ModalForm */
  const schemaModal = yup
    .object()
    .shape({
      fullName: yup
        .string()
        .required(stores.translate('full_name_input_required'))
        .matches(
          /^[a-zA-ZёЁа-яА-Я0-9\s-']+$/,
          stores.translate('full_name_input_special_characters_validation'),
        )
        .min(2, stores.translate('full_name_input_validation')),
      email: yup
        .string()
        .email(stores.translate('email_input_validation'))
        .required(stores.translate('email_input_required')),
    })
    .required();

  const ModalForm = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(schemaModal),
    defaultValues: {
      fullName: '',
      email: '',
    },
  });
  const modalFields = ModalForm.watch();

  const sendRequest = () => {
    setLoading(true);
    fetch(URLS.SEND_INTENTION_REQUEST, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        fullName: modalFields.fullName.trim(),
        email: modalFields.email.trim(),
        amount: Number(usd),
        language: stores.language,
        agreed: true,
      }),
    })
      .then(() => {
        ModalForm.reset({
          fullName: '',
          email: '',
        });
        /* Set to default value */
        setUsd(500);
        close();
        gtagEvent(GOOGLE_ANALYTICS_CONSTANTS.APPLICATION_SEND);
        setLoading(false);
        openPopUp();
      })
      .catch((error) => {
        alert(error);
        console.error(error);
        setLoading(false);
        close();
      });
  };

  return (
    isOpen && (
      <div className={styles.ModalContainer}>
        <div className={styles.Modal}>
          <button
            type="button"
            className={styles.ModalClose}
            onClick={() => {
              ModalForm.reset({
                fullName: '',
                email: '',
              });
              gtagEvent(GOOGLE_ANALYTICS_CONSTANTS.CLOSE_MODAL);
              setUsd(500);
              close();
            }}
          >
            <img src={Close} alt="Close" loading="lazy" />
          </button>
          <p
            className={styles.ModalTitle}
            dangerouslySetInnerHTML={{ __html: stores.translate('proposal_early_investment') }}
          />
          <Input
            placeholder={stores.translate('full_name_input_placeholder')}
            canError
            label={stores.translate('name_and_surname')}
            name="fullName"
            register={ModalForm.register}
            error={ModalForm.formState.errors?.fullName}
            autoComplete="off"
          />
          <Input
            type="email"
            placeholder={stores.translate('email_input_placeholder')}
            canError
            label={stores.translate('email')}
            name="email"
            register={ModalForm.register}
            error={ModalForm.formState.errors?.email}
            autoComplete="off"
          />
          <InvestInput value={usd} onInput={setUsd} stores={stores} />
          <div className={styles.SendButton}>
            <Button
              isSpinning={isLoading}
              onClick={() => {
                gtagEvent(GOOGLE_ANALYTICS_CONSTANTS.CLICK_SEND);
                ModalForm.handleSubmit(sendRequest)();
              }}
              text={stores.translate('proposal_send_proposal')}
            />
          </div>
          <p
            className={styles.ModalText}
            dangerouslySetInnerHTML={{ __html: stores.translate('proposal_agreement') }}
          />
        </div>
      </div>
    )
  );
};

const Services = ({ open, stores }) => {
  return (
    <div className={styles.Services}>
      <div className={styles.ServicesContent}>
        <p className={styles.ServicesTitle}>{stores.translate('home_title')}</p>
        <p className={styles.ServicesText}>{stores.translate('home_description')}</p>
        <img
          src={MobileSolarSystem}
          alt="MobileSolarSystem"
          loading="lazy"
          className={classnames(
            styles.SolarSystem,
            stores.language === 'en' && styles.SolarSystemEng,
          )}
        />
      </div>
      <div className={styles.MobileButtonContainer}>
        {/* <Button
          onClick={() => {
            gtagEvent(GOOGLE_ANALYTICS_CONSTANTS.CLICK_JOIN_TOP);
            open();
          }}
          text={stores.translate('join')}
        /> */}
      </div>
    </div>
  );
};

const Transformation = ({ stores }) => {
  return (
    <div className={styles.TransformationContainer}>
      <div className={styles.TransformationContent}>
        <p className={styles.TransformationTitle}>
          {stores.translate('home_transformation_title')}
        </p>
        <p className={styles.TransformationText}>
          {stores.translate('home_transformation_text')[0]}
        </p>
        <p className={styles.TransformationText}>
          {stores.translate('home_transformation_text')[1]}
        </p>
      </div>
      <div className={styles.TransformationMobile}>
        <img src={MobilePhone} alt="MobilePhone" loading="lazy" />
      </div>
    </div>
  );
};

const BlockchainGame = ({ stores }) => {
  return (
    <div className={styles.BlockchainGameContainer}>
      <div className={styles.PlainDeepBlueBox} />
      <img
        src={BlockchainGameImage}
        alt="blockchain_game_image_mobile"
        className={styles.BlockchainGameImage}
      />
      <img src={HoneywoodLogo} alt="honeywood_logo" className={styles.HoneywoodLogo} />
      <div className={styles.InfoBlockContainer}>
        <p className={styles.InfoBlockTitle}>{stores.translate('blockchain_game_title')}</p>
        <p
          className={styles.InfoBlockSubTitle}
          dangerouslySetInnerHTML={{
            __html: sanitize(stores.translate('blockchain_game_subtitle')),
          }}
        />
        <div className={styles.JoinButtonContainer}>
          <a href="https://honeywood.io/" target="_blank" rel="noreferrer">
            <HoneyButton
              width={
                stores.language === 'Eng' || stores.language === 'Est'
                  ? 160
                  : stores.language === 'Esp'
                  ? 250
                  : 260 // Rus
              }
              title={stores.translate('join')}
              colorType={ColorType.ORANGE}
              size={SizeButton.SMALL}
              onClick={() => {}}
              valueScaleAnimation={0.95}
              classnames={{
                Title: styles.JoinButtonTitle,
              }}
              uniqID={UNIQ_ID_BUTTON.BlockchainGameSection.JoinMobile}
            />
          </a>
        </div>
      </div>
      <img src={BeeLeft} alt="bee_right" className={styles.BeeLeft} />
      <img src={BeeRight} alt="bee_right" className={styles.BeeRight} />
    </div>
  );
};

const HoneyWood = ({ stores }) => {
  return (
    <div className={styles.HoneyWoodContainer}>
      <div className={styles.HoneyWoodImageContainer}>
        <img
          src={HoneyWoodLandingMobile}
          alt="honeywood_top_mobile"
          className={styles.HoneyWoodImageHeader}
        />
      </div>
      <div className={styles.HoneyWoodTextContainer}>
        <p
          className={styles.AcquaintanceTitle}
          dangerouslySetInnerHTML={{ __html: stores.translate('article_honeywood_title') }}
        />
        <p
          className={styles.AcquaintanceText}
          dangerouslySetInnerHTML={{ __html: stores.translate('blockchain_game_subtitle') }}
        />
      </div>
      <Link
        className={styles.HoneyWoodCardContainer}
        to="/honeywood"
        target="_top"
        onClick={() => {
          gtagEvent(GOOGLE_ANALYTICS_CONSTANTS.CLICK_HONEYWOOD_PAGE);
        }}
      >
        <HoneywoodCard
          size="EconomyCoinSize"
          isMobile
          classNames={{ Container: styles.HoneyWoodCard }}
        />
      </Link>
    </div>
  );
};
const Acquaintance = ({ stores }) => {
  return (
    <div className={styles.AcquaintanceContainer}>
      <div
        className={styles.AcquaintanceTitle}
        dangerouslySetInnerHTML={{ __html: stores.translate('acquaintance_title') }}
      />
      <div
        className={styles.AcquaintanceText}
        dangerouslySetInnerHTML={{ __html: stores.translate('acquaintance_text') }}
      />
    </div>
  );
};

const Info = () => {
  return (
    <div className={styles.InfoContainer}>
      <Link
        className={styles.LinkCardContainer}
        to="/articles/moneta-today-ecosystem"
        target="_top"
        onClick={() => {
          gtagEvent(GOOGLE_ANALYTICS_CONSTANTS.CLICK_SUMMARY_ARTICLE);
        }}
      >
        <SummaryCard size="EconomyCoinSize" />
      </Link>
      <Link
        className={styles.LinkCardContainer}
        to="/articles/ecosystem-or-metaverse"
        target="_top"
        onClick={() => {
          gtagEvent(GOOGLE_ANALYTICS_CONSTANTS.CLICK_ECOSYSTEM_ARTICLE);
        }}
      >
        <EcosystemCard size="EconomyCoinSize" isMobile />
      </Link>
      <Link
        className={styles.LinkCardContainer}
        to="/articles/moneta-today-mainnet"
        target="_top"
        onClick={() => {
          gtagEvent(GOOGLE_ANALYTICS_CONSTANTS.CLICK_MAINNET_ARTICLE);
        }}
      >
        <MainnetCard size="EconomyCoinSize" />
      </Link>
    </div>
  );
};

const Principle = ({ stores }) => {
  return (
    <div className={styles.PrincipleContainer}>
      <p className={styles.PrincipleTitle}>{stores.translate('principle_title')}</p>
      <p
        className={styles.PrincipleText}
        dangerouslySetInnerHTML={{ __html: stores.translate('principle_text') }}
      />
      <div className={styles.PrincipleAggregator}>
        <p className={styles.PrincipleAggregatorSharp}>#</p>
        <p className={styles.PrincipleAggregatorTitle}>
          {stores.translate('principle_taxi_aggregator')}
        </p>
      </div>
      <p className={styles.PrincipleAggregatorText}>
        {stores.translate('principle_traditional_profit')}
      </p>
      <img src={TaxiBefore} alt="TaxiBefore" loading="lazy" />
      <div className={styles.PrincipleServiceCommissionContainer}>
        <p className={styles.PrincipleServiceCommissionDigits}>-$25&nbsp;</p>
        <p className={styles.PrincipleServiceCommissionText}>
          {stores.translate('principle_service_commission')}
        </p>
      </div>
      <div className={styles.PrincipleTaxiCompanyCommissionContainer}>
        <p className={styles.PrincipleTaxiCompanyCommissionDigits}>-$7&nbsp;</p>
        <p className={styles.PrincipleTaxiCompanyCommissionText}>
          {stores.translate('principle_taxi_company_commission')}
        </p>
      </div>
      <div className={styles.PrincipleTaxesCommissionContainer}>
        <p className={styles.PrincipleTaxesDigits}>-$6&nbsp;</p>
        <p className={styles.PrincipleTaxesText}>{stores.translate('principle_taxes')}</p>
      </div>
      <p className={styles.PrincipleAggregatorText}>{stores.translate('principle_deb_profit')}</p>
      <img src={TaxiAfter} alt="TaxiAfter" loading="lazy" />
      <div className={styles.PrincipleServiceCommissionContainer}>
        <p className={styles.PrincipleServiceCommissionDigits}>-$1&nbsp;</p>
        <p className={styles.PrincipleServiceCommissionText}>
          {stores.translate('principle_service_commission')}
        </p>
      </div>
    </div>
  );
};

const Coin = ({ stores }) => {
  const isMobileSize = useMediaQuery('(max-width: 375px)');
  return (
    <>
      <div className={styles.CoinContainer}>
        <div className={styles.CoinTextContainer}>
          <p className={styles.CoinTitle}>{stores.translate('coin_title')}</p>
          <p className={styles.CoinText}>{stores.translate('coin_text')}</p>
        </div>
        <Link
          to={URLS.MONETA_TODAY_PRESENTATION}
          target="_blank"
          rel="noreferrer"
          className={styles.CoinLink}
        >
          <Button
            isMedium
            text={stores.translate('find_out_more')}
            onClick={() => {
              gtagEvent(GOOGLE_ANALYTICS_CONSTANTS.CLICK_FINDOUTMORE);
            }}
          />
        </Link>
        <div className={styles.CoreContainer}>
          {isMobileSize ? (
            <img src={MobileCore} alt="MobileCore" loading="lazy" className={styles.CoinCore} />
          ) : (
            <img src={Core} alt="MobileCore" loading="lazy" className={styles.CoinCore} />
          )}
        </div>
      </div>
    </>
  );
};

const Economy = ({ stores }) => {
  return (
    <div className={styles.EconomyContainer}>
      <p className={styles.EconomyContainerTitle}>{stores.translate('economy_title')}</p>
      <div className={styles.EconomyCards}>
        <P2PCard size="EconomyCoinSize" />
        <EcosystemExchangeCard size="EconomyCoinSize" />
        <ExchangeCard size="EconomyCoinSize" />
      </div>
    </div>
  );
};

const InvestTimeAndMoney = ({ open, stores }) => {
  return (
    <div className={styles.InvestTimeAndMoneyContainer}>
      <div className={styles.InvestTimeAndMoney}>
        <p className={styles.InvestTimeAndMoneyTitle}>
          {stores.translate('invest_time_and_money')}
        </p>
      </div>
      <div className={styles.MobileButtonContainer}>
        <Button
          onClick={() => {
            gtagEvent(GOOGLE_ANALYTICS_CONSTANTS.CLICK_JOIN_BOTTOM);
            open();
          }}
          text={stores.translate('join')}
        />
      </div>
    </div>
  );
};

export default MobileHomePage;
