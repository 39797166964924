import React, { useEffect, useRef, useState } from 'react';
import Layout from '@components/Layout';
import { useLocation } from 'react-router-dom';

import styles from './privacy.module.scss';

const PointerP = ({ children }) => {
  return <p className={styles.pointerP}>● {children}</p>;
};

const PrivacyPage = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  /* eslint-disable */
  return (
    <Layout>
      <div className={styles.Container}>
        <h1 className={styles.h1}>Privacy Policy</h1>
        <h2 className={styles.h2}>GENERAL PROVISIONS</h2>
        <p className={styles.p}>
          Thank you for visiting the website of MToday OÜ. This Privacy Policy is governed by the
          provisions of the Cybersecurity Act, the Public Information Act, and the Personal Privacy
          Protection Act. Current Privacy Policy describes methods of ensuring discretion with
          respect to information received from the User of this website. The Service Provider
          acknowledges that when visiting the MToday OÜ website, the confidence of individuals and
          companies in the safe handling of their personal data is fundamental.
        </p>
        <p className={styles.p}>
          This Privacy Policy describes what data is collected and how it is used. Since the
          provisions described below apply only to this website, we recommend that you study the
          Privacy Policy of any other website, including websites of other state bodies, to which
          the User follows the links when using the MToday OÜ website.
        </p>
        <p className={styles.p}>
          This page is used to inform website visitors about our policies regarding the collection,
          use and disclosure of personal information. The Policy applies to all personal data and
          information that MToday OÜ may receive from the User while using the website. The use of
          this website by the User means their unconditional acceptance of this Policy and the
          conditions for processing their Personal data specified therein. In case of disagreement
          with these conditions, the User must immediately stop using the website.
        </p>
        <p className={styles.p}>
          This Privacy Policy applies to all customers using the MToday OÜ website. Moreover, if the
          User uses our Service, they agree to the collection and use of information in accordance
          with our Privacy Policy. The personal data that we collect is used to improve our service
          and the functionality of the website offered to the User.
        </p>
        <p className={styles.p}>
          Please note that MToday OÜ will not be able to effectively serve the User or offer its
          services to the User if they decide not to share this information. Any unsolicited
          information from the User is provided voluntarily and will not be transferred to anyone,
          except as described in this Privacy Policy.
        </p>
        <h2 className={styles.h2}>COLLECTION AND PROCESSING OF INFORMATION</h2>
        <p className={styles.p}>
          MToday OÜ automatically collects some technical information when visiting the website in
          order to provide the User with the best experience. The Service Provider collects and
          stores only those Personal data and information that are necessary for the full use of the
          website, including for:
        </p>
        <PointerP>Providing the User with access to online services on the website</PointerP>
        <PointerP>Communication with the User</PointerP>
        <PointerP>
          Improving the quality of the website, its usability and the development of new online
          services and functions
        </PointerP>
        <PointerP>Provision of additional services to the User</PointerP>
        <p className={styles.p}>
          The Service Provider uses Google Analytics web measurement technology to automatically
          track how the User interacts with MToday OÜ, including which websites they come from, what
          they do on the website and whether they performed any predefined tasks while on this
          website. Aggregated data is used to improve our user experience and meet the needs of our
          customers; track operational issues and visitor numbers; prevent fraud and improve
          efficiency; and provide security and integrity of the website. For each page visited,
          MToday OÜ collects and stores only the following technical information:
        </p>
        <PointerP>URL of the visited web page of MToday OÜ</PointerP>
        <PointerP>Date and time of access</PointerP>
        <PointerP>Size of the visited web page</PointerP>
        <PointerP>The status of the execution or success of the web page request</PointerP>
        <PointerP>Internet domain and IP address from which the website was accessed</PointerP>
        <PointerP>The type of browser and operating system used to access the website</PointerP>
        <PointerP>URL of the referring page (if provided by the browser)</PointerP>
        <p className={styles.p}>
          This site does not track information about people and does not share this data with anyone
          outside MToday OÜ, except for cases when it is necessary for law enforcement purposes.
        </p>
        <p className={styles.p}>Types of Data Collected:</p>
        <PointerP>
          Personal Data. While using our Service, we may ask you to provide us with certain
          personally identifiable information that can be used to contact or identify you ("Personal
          Data"). Personally identifiable information may include, but is not limited to: Email
          address Cookies and Usage Data
        </PointerP>
        <PointerP>
          Usage Data. We may also collect information that your browser sends whenever you visit our
          Service or when you access the Service by or through a mobile device ("Usage Data"). This
          Usage Data may include information such as your computer's Internet Protocol address (e.g.
          IP address), browser type, browser version, the pages of our Service that you visit, the
          time and date of your visit, the time spent on those pages, unique device identifiers and
          other diagnostic data. When you access the Service by or through a mobile device, this
          Usage Data may include information such as the type of mobile device you use, your mobile
          device unique ID, the IP address of your mobile device, your mobile operating system, the
          type of mobile Internet browser you use, unique device identifiers and other diagnostic
          data.
        </PointerP>
        <PointerP>
          Tracking Cookies Data. We use cookies and similar tracking technologies to track the
          activity on our Service and hold certain information.Cookies are files with small amount
          of data which may include an anonymous unique identifier. Cookies are sent to your browser
          from a website and stored on your device. Tracking technologies also used are beacons,
          tags, and scripts to collect and track information and to improve and analyze our Service.
        </PointerP>
        <p className={styles.p}>
          You can instruct your browser to refuse all cookies or to indicate when a cookie is being
          sent. However, if you do not accept cookies, you may not be able to use some portions of
          our Service. Examples of Cookies we use: Session Cookies. We use Session Cookies to
          operate our Service. Preference Cookies. We use Preference Cookies to remember your
          preferences and various settings. Security Cookies. We use Security Cookies for security
          purposes.
        </p>

        <h2 className={styles.h2}>DATA PROTECTION</h2>
        <p className={styles.p}>
          We value the confidentiality of our Users, therefore we strive to take the necessary
          organisational and technical measures in order to ensure the protection of the User’s
          information from illegal actions of third parties, such as destruction, modification,
          blocking, copying, distribution, etc. It should be noted that 100% secure data
          transmission over the Internet cannot be guaranteed. MToday OÜ asks to be aware of the
          responsibility for independently adopting security measures to protect the User’s personal
          information.
        </p>
        <p className={styles.p}>
          The service provider restricts employees’ access to the User’s personal information (taken
          from the website) only to those employees who need access to information in the
          performance of their official duties. Employees with access to this information must
          comply with appropriate procedures with respect to any disclosure of the user information
          as part of our continued commitment to the security of electronic content as well as the
          electronic transmission of information.
        </p>

        <h2 className={styles.h2}>CHANGES TO PRIVACY POLICY</h2>
        <p className={styles.p}>
          From time to time, MToday OÜ may revise this Privacy Policy in order to make changes to
          our methods of collecting and using personal data or changes due to amendments to
          legislation. Our Privacy Policy is subject to change without any special notice to the
          User. Therefore, we advise the User to periodically review this page for changes. If this
          Privacy Policy is significantly changed, this will be announced by posting on the website.
          To collect personal information from the User that is not provided for in this Privacy
          Policy, the Service Provider will ask for consent before using user information. Changes
          take effect immediately upon posting.
        </p>
        <p className={styles.p}>
          The latest Privacy Policy incorporates elements of the General Data Protection Regulation
          (GDPR) as we operate in accordance with its rules for the processing of personal
          information in the European Economic Area (EEA).
        </p>

        <h2 className={styles.h2}>DATA STORAGE CONDITIONS</h2>
        <p className={styles.p}>
          The service provider will only store the User’s personal data for as long as it is
          necessary to complete tasks, including compliance with any legal, accounting or reporting
          requirements.
        </p>
        <p className={styles.p}>
          The User’s information is stored in accordance with the internal rules of the website and
          is processed using automated systems, except for cases where manual processing is
          necessary in connection with the fulfillment of legal requirements.
        </p>
        <p className={styles.p}>
          The service provider may anonymise the User’s personal data for research or statistical
          purposes. We can also use anonymous information for an unlimited time without further
          notice to the User.
        </p>
        <p className={styles.p}>
          With regard to the User’s personal data, its confidentiality is preserved, except for
          cases of voluntary provision by the User of information about himself for general access
          to an unlimited number of persons.
        </p>

        <h2 className={styles.h2}>DATA STORAGE TIMEFRAME</h2>
        <p className={styles.p}>
          Anonymised data of website visitors, such as cookies, is stored in our database for
          exactly 26 months. During the allotted period of time, the Google Analytics will analyse
          the behavior of website visitors for statistical purposes. The personal information of the
          forms filled out on the website, where the User enters their contact information, can be
          deleted at the request of the User.
        </p>

        <h2 className={styles.h2}>DATA TRANSFER</h2>
        <p className={styles.p}>
          MToday OÜ may have to transfer the User’s information to third parties. In such a case,
          the Service Provider requires that all parties respect the security of the User’s personal
          data and treat them in accordance with the law. We do not allow our third party service
          providers to use our customers’ personal information for their own purposes. They have the
          right to process user personal data only for the specified purposes in accordance with the
          instructions of MToday OÜ.
        </p>
        <p className={styles.p}>
          The website has the right to transfer the User’s information to third parties in the
          following cases:
        </p>
        <PointerP>
          The User has expressed their consent to the transfer of the data specified in this
          Agreement, or in the process of working with the website
        </PointerP>
        <PointerP>
          The transfer is necessary to provide a service to the User (for example, the transfer of a
          part of the User’s data to the partners of MToday OÜ for the latter to get in touch to the
          User).
        </PointerP>
        <PointerP>
          In order to ensure the possibility of protecting the rights and legitimate interests of
          the Service Provider or third parties in cases where the User violates the Terms of Use.
        </PointerP>

        <h2 className={styles.h2}>LINKS TO OTHER SITES</h2>
        <p className={styles.p}>MToday OÜ contains links to other websites.</p>
        <p className={styles.p}>
          This Privacy Policy applies only to MToday OÜ and does not apply to other government
          services and transactions to which we link. These services have their own Terms of Use and
          Privacy Policy.
        </p>
        <PointerP>Links to another websites</PointerP>
        <p className={styles.p}>
          If the User navigates to another website from the MToday OÜ website, it is recommended to
          read the Privacy Policy on this website.
        </p>
        <PointerP>Link to MToday OÜ from another website</PointerP>
        <p className={styles.p}>
          If the User navigates to the website of MToday OÜ from another website, the Service
          Provider receives personal information from the other website. In the Privacy Policy of
          the website from which the User came, the Terms of Use of personal information in this
          case are described in detail.
        </p>

        <h2 className={styles.h2}>CHANGE OF USER DATA</h2>
        <p className={styles.p}>
          The consent to the processing of information expressed by the User by joining this Privacy
          Policy is valid indefinitely but can be withdrawn at any time on the basis of a personal
          statement sent to the Service Provider.
        </p>
        <p className={styles.p}>
          The User can send a discretionary inquiry to MToday OÜ to find out which personal
          information related to this User has been collected through the website.
        </p>

        <h2 className={styles.h2}>DISCLAIMER</h2>
        <p className={styles.p}>
          The Disclaimer presented in this Privacy Policy should not be construed as offering
          business, legal or other advice, proof of company insolvency or guaranteed security of
          processing user data. The information provided on this website is intended to provide
          immediate access to information open to the public. Even though every attempt is made to
          provide the User with relevant and verified information, MToday OÜ recognises the factor
          of human/mechanical error. By relying on this, the Service Provider represented by the
          company makes no statements about the accuracy and timeliness of the information presented
          on this website and denies any implicit or explicit user warranties in this respect.
        </p>
        <p className={styles.p}>
          The information provided on this website is not exhaustive and does not imply any elements
          of a contractual relationship. And although the Service Provider makes every effort to
          provide verified data and up-to-date information published on our website, such materials
          in no way constitute professional advice.
        </p>
        <p className={styles.p}>
          The Disclaimer section applies to all persons reading any material or social media
          published by MToday OÜ. Please note that this section may be changed or updated at any
          time.
        </p>
        <p className={styles.p}>
          All website materials are for informational purposes only. The content of the texts is not
          sponsored advertising, therefore, does not constitute an invitation or inducement to any
          investment activity.
        </p>
        <p className={styles.p}>
          All documentation published on the website is not a formal agreement of any kind, and the
          information provided here is for informational purposes only and is subject to change
          without any preliminary notice.
        </p>
      </div>
    </Layout>
  );
};

export default PrivacyPage;
