import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';
import { gtagEvent } from '@utils/common';
import { GOOGLE_ANALYTICS_CONSTANTS } from '@src/constants/googleAnalyticsEvents';
import URLS from '@src/constants/urls';
import Logger from '@utils/logger';

import Code from '@src/assets/svg/Code.svg';
import ToTop from '@src/assets/svg/ToTop.svg';
import Twitter from '@src/assets/svg/Twitter.svg';
import TelegramPlane from '@src/assets/svg/TelegramPlane.svg';
import AppStoreLogo from '@src/assets/svg/appstore_logo.svg';
import GooglePlayLogo from '@src/assets/svg/googleplay_logo.svg';
import MediumM from '@src/assets/svg/MediumM.svg';
import Envelope from '@src/assets/svg/Envelope.svg';
import { useStores } from '@src/stores';

import styles from './footerStyles.module.scss';

const LinkIcon = ({ link, icon, onClick }) => {
  return (
    <a href={link} target="_blank" rel="noreferrer" className={styles.LinkItem} onClick={onClick}>
      <img src={icon} alt="icon" loading="lazy" />
    </a>
  );
};

const LeftBlock = observer(() => {
  const stores = useStores();
  // eslint-disable-next-line no-unused-vars
  const _ = stores.language;
  return (
    <div className={styles.LeftBlock}>
      {/* <div className={styles.Links}>
        <LinkIcon
          link="https://t.me/monetatoday"
          icon={TelegramPlane}
          onClick={() => {
            gtagEvent(GOOGLE_ANALYTICS_CONSTANTS.CLICK_TELEGRAM);
          }}
        />
        <LinkIcon
          link="http://monetatoday.medium.com"
          icon={MediumM}
          onClick={() => {
            gtagEvent(GOOGLE_ANALYTICS_CONSTANTS.CLICK_MEDIUM);
          }}
        />
        <LinkIcon
          link="https://twitter.com/monetatoday"
          icon={Twitter}
          onClick={() => {
            gtagEvent(GOOGLE_ANALYTICS_CONSTANTS.CLICK_TWITTER);
          }}
        />
      </div> */}
      <div className={styles.NameAndPolicy}>
        <p className={styles.Text}>{stores.translate('all_rights_reserved')}</p>
        <Link
          to='/privacy'
          className={styles.PrivacyLink}
          onClick={() => {
            gtagEvent(GOOGLE_ANALYTICS_CONSTANTS.CLICK_PRIVACY);
          }}
        >
          <p className={styles.Text}>{stores.translate('privacy_policy')}</p>
        </Link>
        <a
          href={URLS.TERMS_OF_USE}
          target="_blank"
          rel="noreferrer"
          className={styles.PrivacyLink}
          onClick={() => {
            gtagEvent(GOOGLE_ANALYTICS_CONSTANTS.CLICK_TERMS);
          }}
        >
          <p className={styles.Text}>{stores.translate('terms_of_use')}</p>
        </a>
      </div>
      <div className={styles.Mail}>
        <img src={Envelope} alt="Envelope" loading="lazy" className={styles.MailIcon} />
        <a
          href="mailto:contact@moneta.today"
          className={styles.MailLink}
          target="_blank"
          rel="noreferrer"
          onClick={() => {
            gtagEvent(GOOGLE_ANALYTICS_CONSTANTS.CLICK_MAIL);
          }}
        >
          <p className={styles.MailText}>contact@moneta.today</p>
        </a>
      </div>
      <div className={styles.AppLinks}>
        <a
          href="https://apps.apple.com/ru/app/moneta-today/id1605931536"
          className={styles.AppStoreLogoLink}
          onClick={() => {
            Logger.infoMainNotify({ nameNotify: 'info notify', section: 'info', meta: { infoLocation: window.location, userAgent: navigator.userAgent }, useInProduction: true });
          }}
        >
          <img src={AppStoreLogo} alt="AppStoreLogo" loading="lazy" />
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=moneta.today"
          className={styles.GooglePlayLogoLink}
        >
          <img src={GooglePlayLogo} alt="GooglePlayLogo" loading="lazy" />
        </a>
      </div>
    </div>
  );
});

const TextLink = ({ text, link }) => {
  return (
    <a href={link} className={styles.TextLink}>
      <p className={styles.Text}>{text}</p>
    </a>
  );
};

const RightBlock = observer(() => {
  const stores = useStores();
  // eslint-disable-next-line no-unused-vars
  const _ = stores.language;
  return (
    <div className={styles.RightBlock}>
      <TextLink text={stores.translate('about_us')} link="/" />
      <TextLink text={stores.translate('for_press')} link="/" />
      <TextLink text={stores.translate('agreement')} link="/" />
      <TextLink text={stores.translate('work_at_moneta_today')} link="/" />
      <TextLink text={stores.translate('support')} link="/" />
    </div>
  );
});

const Footer = () => {
  const location = useLocation();
  const toTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className={styles.MainContainer}>
      {location.pathname === '/' && (
        <button type="button" onClick={toTop} className={styles.ButtonToTop}>
          <img src={ToTop} alt="ToTop" loading="lazy" />
        </button>
      )}
      <div className={styles.Container}>
        <LeftBlock />
        {false && <RightBlock />}
      </div>
      <img src={Code} alt="Code" loading="lazy" className={styles.Code} />
    </div>
  );
};

export default Footer;
