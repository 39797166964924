import React from 'react';
import { classnames } from '@bem-react/classnames';
import Spinner from '@src/components/Spinner';
import styles from './button.module.scss';

const Button = ({ text, onClick, isMedium, classNames = {}, isSpinning = false }) => {
  return (
    <button
      type="button"
      className={classnames(
        styles.BigButtonContainer,
        classNames.Container,
        isMedium ? styles.BigButtonContainerMedium : '',
      )}
      onClick={onClick}
    >
      {!isMedium && <div className={styles.BigButtonShadow} />}
      <div className={styles.BigButtonLightShadow} />
      <div className={classnames(styles.BigButtonText, classNames.ButtonText)}>
        {isSpinning && <Spinner />}
        {text}
      </div>
    </button>
  );
};

export default Button;
