/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';
import { gtagEvent } from '@utils/common';
import { GOOGLE_ANALYTICS_CONSTANTS } from '@src/constants/googleAnalyticsEvents';
import { useStores } from '@src/stores';
import { ArticleName, ArticlePage } from '../components/ArticlePage';

const MainnetArticle = observer(() => {
  const stores = useStores();
  const _ = stores.language;

  /* useEffect for Google Analytics */
  const location = useLocation();
  useEffect(() => {
    gtagEvent(GOOGLE_ANALYTICS_CONSTANTS.VISIT_MAINNET_ARTICLE);
    // window.gtag('event', 'visitToMainnetArticle', {
    //   page_title: location.pathname,
    // });
    // console.log('visitToMainnetArticle: ', location.pathname);
  }, [location]);

  return (
    <ArticlePage
      article={ArticleName.monetaMainnet}
      title={stores.translate('article_mainnet_title')}
      text={stores.translate('article_mainnet_text')}
    />
  );
});

export default MainnetArticle;
