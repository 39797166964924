import React, { useEffect, useRef, useState } from 'react';
import { cn } from '@bem-react/classname';
import './styles.scss';
import SelectArrow from '@src/assets/svg/SelectArrow.svg';
import SelectBackground from '@src/assets/svg/SelectBackground.svg';
import { classnames } from '@bem-react/classnames';

const selectCN = cn('Select');

const Select = ({
  value = '',
  label = '',
  /* В объектах для values надо обязательно создавать поля title и _id, иначе будут ошибки! 
  Но в этом экземпляре я это удалил */
  values = [],
  onChange = () => {},
  readOnly = false,
  classNames = {},
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [titleValue, setTitleValue] = useState(value);
  const ref = useRef(null);
  const refSelect = useRef(null);
  const toggling = () => {
    setIsOpen(!isOpen);
  };
  // const [directionOpenedListTop, setDirectionOpenedListTop] = useState(false);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  /* Чтобы, когда мы переиспользуем компонент c Select'ом, 
   то, если не делать так, то не обновляется то, что в начале отображается внутри Select'a,
   т.е. само value */
  useEffect(() => {
    setTitleValue(value);
  }, [value]);

  useEffect(() => {
    const refSize = refSelect.current.getBoundingClientRect();
    // if (window.innerHeight - refSize.bottom < 240) {
    //   setDirectionOpenedListTop(true);
    // }
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  const onOptionClicked = (selectValue) => {
    setIsOpen(false);
    onChange(selectValue);
    // setTitleValue(selectValue.title); // а если в качестве title у нас объект, где нет поля title?
    setTitleValue(selectValue); // а если в качестве title у нас объект, где нет поля title?
  };

  return (
    <div className={classnames(selectCN('Container'), classNames.Container)} ref={refSelect}>
      {!!label && <p className={selectCN('Label')}>{label}</p>}
      <div
        className={classnames(selectCN('SelectContainer'), classNames.SelectContainer)}
        onClick={toggling}
        onKeyPress={toggling}
        role="button"
        tabIndex={0}
        ref={ref}
      >
        <div className={classnames(selectCN('Header'), classNames.Header)}>
          <p className={classnames(selectCN('HeaderTitle'), classNames.HeaderTitle)}>
            {titleValue}
          </p>
          <img src={SelectArrow} alt="selectArrow" />
        </div>
        <div
          className={classnames(
            selectCN('DropDownContainer'),
            // selectCN('DropDownContainer', { directionOpenedListTop }),
            classNames.DropDownContainer,
          )}
        >
          {isOpen && !readOnly && (
            <ul className={classnames(selectCN('DropDownList'), classNames.DropDownList)}>
              {values &&
                values.map((selectValue) => {
                  return (
                    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                    <li
                      className={classnames(selectCN('ListItem'), classNames.ListItem)}
                      onClick={() => onOptionClicked(selectValue)}
                      onKeyPress={() => onOptionClicked(selectValue)}
                      // key={selectValue._id}
                      key={selectValue}
                    >
                      {/* {selectValue.title} */}
                      {selectValue}
                    </li>
                  );
                })}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export default Select;
