// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase, set, ref } from "firebase/database";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDuhRMTfGTo2JZOaBdeZfdl0eNjiDIsGDQ",
  authDomain: "monetatoday-web.firebaseapp.com",
  projectId: "monetatoday-web",
  storageBucket: "monetatoday-web.appspot.com",
  messagingSenderId: "1039092650861",
  appId: "1:1039092650861:web:97c5ab27f7ee46dbb00064",
  measurementId: "G-ZL16HW87WR",
  databaseURL: 'https://monetatoday-web-default-rtdb.asia-southeast1.firebasedatabase.app/'
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

/** 
 * Database
*/
// Initialize Realtime Database and get a reference to the service
const database = getDatabase(app);

const writeData = async ({ data }) => {
  return set(ref(database, `users/production + ${Math.round(Math.random()*1000)}`), {
    data
  });
}

export default {
  app,
  writeData,
}