/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import classnames from 'classnames';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { debounce } from 'lodash';
import { gtagEvent } from '@utils/common';
import { GOOGLE_ANALYTICS_CONSTANTS } from '@src/constants/googleAnalyticsEvents';
import URLS from '@src/constants/urls';
import Input from '@src/components/Input';
import Button from '@src/components/Button';
import CheckBox from '@src/components/CheckBox';
import Close from '@src/assets/svg/Close.svg';
import styles from './modal.module.scss';

const debounced = debounce(() => {
  gtagEvent(GOOGLE_ANALYTICS_CONSTANTS.SLIDE_INVEST);
}, 1000);

const InvestInput = ({ value, onInput, stores, fromHoneyWood = false }) => {
  const min = fromHoneyWood ? 100 : 500;
  const max = 1000000;

  const onChange = (event) => {
    onInput(event.target.value);
  };

  return (
    <>
      <p className={styles.ModalInvestText}>{stores.translate('proposal_how_much_invest')}</p>
      <div className={classnames(styles.ModalInvestSum, styles.ModalInvestInput)}>
        {`${value === '1000000' ? '>1000000' : value} USD`}
      </div>
      <input
        className={styles.ModalInvestSlider}
        // id="typeinp"
        type="range"
        min={min}
        max={max}
        value={value}
        onChange={(event) => {
          onChange(event);
          debounced();
        }}
        step={min}
      />
    </>
  );
};

const Modal = ({ isOpen, close, stores, openPopUp, fromHoneyWood = false }) => {
  const initInvestValue = fromHoneyWood ? 100 : 500;
  const [usd, setUsd] = useState(initInvestValue);
  const [isLoading, setLoading] = useState(false);
  const [isLikeToInvest, setLikeToInvest] = useState(false);

  /* Logic for ModalForm */
  const schemaModal = yup
    .object()
    .shape({
      fullName: yup
        .string()
        .matches(
          /^[a-zA-ZёЁа-яА-Я0-9\s-']+$/,
          stores.translate('full_name_input_special_characters_validation'),
        )
        .min(2, stores.translate('full_name_input_validation'))
        .required(stores.translate('full_name_input_required')),
      email: yup
        .string()
        .email(stores.translate('email_input_validation'))
        .required(stores.translate('email_input_required')),
    })
    .required();

  const ModalForm = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(schemaModal),
    defaultValues: {
      fullName: '',
      email: '',
    },
  });
  const modalFields = ModalForm.watch();

  const sendRequest = () => {
    setLoading(true);
    fetch(URLS.SEND_INTENTION_REQUEST, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        fullName: modalFields.fullName.trim(),
        email: modalFields.email.trim(),
        amount: isLikeToInvest ? Number(usd) : fromHoneyWood ? 0 : Number(usd),
        language: stores.language,
        agreed: true,
        meta: fromHoneyWood ? 'fromHoneyWood' : null,
      }),
    })
      .then(() => {
        ModalForm.reset({
          fullName: '',
          email: '',
        });
        /* Set Usd to default value */
        setUsd(initInvestValue);
        close();
        gtagEvent(GOOGLE_ANALYTICS_CONSTANTS.APPLICATION_SEND);
        setLoading(false);
        openPopUp();
      })
      .catch((error) => {
        alert(error);
        console.error(error);
        setLoading(false);
        close();
      });
  };

  return (
    isOpen && (
      <div className={styles.ModalContainer}>
        <div className={styles.Modal}>
          <button
            type="button"
            className={styles.ModalClose}
            onClick={() => {
              ModalForm.reset({
                fullName: '',
                email: '',
              });
              gtagEvent(GOOGLE_ANALYTICS_CONSTANTS.CLOSE_MODAL);
              setUsd(initInvestValue);
              close();
            }}
          >
            <img src={Close} alt="Close" loading="lazy" />
          </button>
          <p
            className={styles.ModalTitle}
            dangerouslySetInnerHTML={{ __html: stores.translate('proposal_early_investment') }}
          />
          <Input
            placeholder={stores.translate('full_name_input_placeholder')}
            canError
            label={stores.translate('name_and_surname')}
            name="fullName"
            register={ModalForm.register}
            error={ModalForm.formState.errors?.fullName}
            autoComplete="no"
          />
          <Input
            type="email"
            placeholder={stores.translate('email_input_placeholder')}
            canError
            label={stores.translate('email')}
            name="email"
            register={ModalForm.register}
            error={ModalForm.formState.errors?.email}
            autoComplete="no"
          />
          {fromHoneyWood && (
            <div className={styles.CheckBoxContainer}>
              <div>
                <CheckBox
                  selected={isLikeToInvest}
                  title={stores.translate('i_would_like_to_invest')}
                  onChange={setLikeToInvest}
                  classNames={{ Container: styles.CheckBox }}
                />
              </div>
              <div />
            </div>
          )}
          {fromHoneyWood ? (
            isLikeToInvest && (
              <InvestInput value={usd} onInput={setUsd} stores={stores} fromHoneyWood />
            )
          ) : (
            <InvestInput value={usd} onInput={setUsd} stores={stores} />
          )}
          <div className={styles.SendButton}>
            <Button
              isSpinning={isLoading}
              onClick={() => {
                gtagEvent(GOOGLE_ANALYTICS_CONSTANTS.CLICK_SEND);
                ModalForm.handleSubmit(sendRequest)();
              }}
              text={stores.translate(
                !fromHoneyWood || isLikeToInvest
                  ? 'proposal_send_proposal'
                  : 'proposal_subscribe_proposal',
              )}
            />
          </div>
          <p
            className={styles.ModalText}
            dangerouslySetInnerHTML={{ __html: stores.translate('proposal_agreement') }}
          />
        </div>
      </div>
    )
  );
};

export default Modal;
