// const BASE_URL = process.env.REACT_APP_BASE_URL;

const URLS = {
  SEND_INTENTION_REQUEST: '/api/v1/monetacoin/intentions',
  MONETA_TODAY_PRESENTATION: 'moneta-today-presentation.pdf',
  PRIVACY_POLICY: 'privacy-policy.pdf',
  TERMS_OF_USE: 'terms-of-use.pdf',
};

export default URLS;
