/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useStores } from 'src/stores';
import Layout from '@src/components/Layout';

import { useMediaQuery } from '@src/helpers/media';

import Logger from '@utils/logger';
import firebase from '@utils/firebase';

import styles from './mobileInstagram.module.scss';

const MobileForInstagramPage = observer(({ queries }) => {
  const stores = useStores();
  // eslint-disable-next-line no-unused-vars
  const _ = stores.language;
  const { isAndroid } = stores.browserInfo;
  // const path = isAndroid ? `https://app.moneta.today${queries}` : `https://app.moneta.today${queries}`;

  // const path = 'https://app.moneta.today/?invitedby=moneta10frau2ggw8vcsgtukqy3lj03vjajzm0e0eq4eh';
  // const path = 'https://app.moneta.today/?invitedby=moneta1uqhvzvydg795nuq02m00479p5fla7gwv7vhxw6';
  // const path = 'https://monetatoday.page.link/rM3L';
  const path = `https://monetatoday.page.link/?link=https://app.moneta.today${queries}&apn=moneta.today&isi=1605931536&ibi=moneta.today&st=Welcome+my+friend&sd=Receive+rewards+everyday+by+installing+this+app&si=https://honeywood.io/favicon/ms-icon-310x310.png&utm_campaign=Instagram+link&utm_medium=cpc&utm_source=instagram`;

  return (
    <Layout>
      <div className={styles.MainContainer}>
        <p className={styles.Title}>{stores.translate('open_app')}</p>
        <a
          href={path}
          className={styles.AppButtonLink}
          onClick={async () => {
            await firebase.writeData({ data: {
              pressed: true,
              isAndroid,
              path,
            } });
          }}
        >
          <p className={styles.Button}>{stores.translate('get_it_app')}</p>
        </a>
      </div>
    </Layout>
  );
});


export default MobileForInstagramPage;
