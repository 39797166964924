import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useStores } from '@src/stores';
import Back from '@src/assets/svg/Back.svg';

import Header from '@src/components/Header';
import Footer from '@src/components/Footer';
import CookiesPopUp from '@src/components/CookiesPopUp';
import URLS from '@src/constants/urls';

import styles from './layout.module.scss';

const BackButton = observer(() => {
  const stores = useStores();
  // eslint-disable-next-line no-unused-vars
  const _ = stores.language;
  return (
    <div className={styles.BackContainer}>
      <Link className={styles.Back} to="/">
        <img src={Back} alt="Back" loading="lazy" className={styles.BackIcon} />
        {/* <Back className={styles.BackIcon} /> */}
        {/* Главная */}
        {stores.translate('home')}
      </Link>
    </div>
  );
});

const Layout = observer(({ children }) => {
  const location = useLocation();
  const stores = useStores();
  const [isOpenCookiesPopUp, setOpenCookiesPopUp] = useState(false);
  useEffect(() => {
    /* CookiesPopUp logic */
    if (!window.localStorage.getItem('isCookiesAccepted')) {
      setOpenCookiesPopUp(true);
    }
  }, []);
  const acceptedCookies = () => {
    window.localStorage.setItem('isCookiesAccepted', true);
  };
  const textHtmlForCookiesPopUp = (
    <p>
      {stores.translate('cookies_popup_text')}
      <a
        target="_blank"
        rel="noreferrer"
        href={URLS.PRIVACY_POLICY}
        className={styles.PrivacyAndTermsLink}
      >
        {stores.translate('privacy_policy_lowercase')}
      </a>
      &nbsp;{stores.translate('and')}&nbsp;
      <a
        target="_blank"
        rel="noreferrer"
        href={URLS.TERMS_OF_USE}
        className={styles.PrivacyAndTermsLink}
      >
        {stores.translate('terms_of_use_lowercase')}
      </a>
      .
    </p>
  );

  return (
    <div className={styles.LayoutComponent}>
      <Header />
      {location.pathname !== '/' && <BackButton />}
      {children}
      <Footer />
      <div className={styles.CookiesPopUpBlock}>
        {isOpenCookiesPopUp && (
          <CookiesPopUp
            isCookiesPopUpOpen={isOpenCookiesPopUp}
            onClick={() => {
              acceptedCookies();
              setOpenCookiesPopUp(false);
            }}
            html={textHtmlForCookiesPopUp}
          />
        )}
      </div>
    </div>
  );
});

export default Layout;
