import React from 'react';
import ReactDOM from 'react-dom';
import { ErrorBoundary } from '@utils/logger';
import App from '@src/App';
import './styles/global.css';
import initFirebase from '@utils/firebase';

if (process.env.REACT_APP_STAGE === 'production') {
  const gtagScript = document.createElement('script');
  gtagScript.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=G-R2XCNQVMZ3');
  document.head.appendChild(gtagScript);

  const gtagScript2 = document.createElement('script');
  gtagScript2.innerHTML =
    "window.dataLayer = window.dataLayer || []; function gtag() { dataLayer.push(arguments); } gtag('js', new Date()); gtag('config', 'G-R2XCNQVMZ3');";
  document.head.appendChild(gtagScript2);
}

// process.env (production)
// FAST_REFRESH: true
// NODE_ENV: "production"
// PUBLIC_URL: ""
// REACT_APP_STAGE: "production"

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root'),
);
