export const GOOGLE_ANALYTICS_CONSTANTS = {
  VISIT_MAIN: 'VISIT_MAIN',
  VISIT_HONEYWOOD: 'VISIT_HONEYWOOD',
  VISIT_SUMMARY_ARTICLE: 'VISIT_SUMMARY_ARTICLE',
  VISIT_ECOSYSTEM_ARTICLE: 'VISIT_ECOSYSTEM_ARTICLE',
  VISIT_MAINNET_ARTICLE: 'VISIT_MAINNET_ARTICLE',

  CLICK_FINDOUTMORE: 'CLICK_FINDOUTMORE',
  CLICK_JOIN_TOP: 'CLICK_JOIN_TOP',
  CLICK_JOIN_BOTTOM: 'CLICK_JOIN_BOTTOM',
  CLICK_JOIN_HONEYWOOD: 'CLICK_JOIN_HONEYWOOD',
  CLICK_SEND: 'CLICK_SEND',
  APPLICATION_SEND: 'APPLICATION_SEND',

  SLIDE_INVEST: 'SLIDE_INVEST',

  CLICK_LANGUAGETOGGLE: 'CLICK_LANGUAGETOGGLE', // пока отключено

  CLICK_SUMMARY_ARTICLE: 'CLICK_SUMMARY_ARTICLE',
  CLICK_ECOSYSTEM_ARTICLE: 'CLICK_ECOSYSTEM_ARTICLE',
  CLICK_MAINNET_ARTICLE: 'CLICK_MAINNET_ARTICLE',
  CLICK_HONEYWOOD_PAGE: 'CLICK_HONEYWOOD_PAGE',

  CLICK_TELEGRAM: 'CLICK_TELEGRAM',
  CLICK_MEDIUM: 'CLICK_MEDIUM',
  CLICK_FACEBOOK: 'CLICK_FACEBOOK',
  CLICK_TWITTER: 'CLICK_TWITTER',
  CLICK_PRIVACY: 'CLICK_PRIVACY',
  CLICK_TERMS: 'CLICK_TERMS',
  CLICK_MAIL: 'CLICK_MAIL',
  CLICK_HONEYWOOD_WEB: 'CLICK_HONEYWOOD_WEB',
  CLICK_HONEYWOOD_CHAT: 'CLICK_HONEYWOOD_CHAT',

  CLOSE_MODAL: 'CLOSE_MODAL',
};

export default {};
