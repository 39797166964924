/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';
import { gtagEvent } from '@utils/common';
import { GOOGLE_ANALYTICS_CONSTANTS } from '@src/constants/googleAnalyticsEvents';
import { useStores } from '@src/stores';
import Layout from '@src/components/Layout';

import { EcosystemCard, CardSize, MainnetCard, SummaryCard } from '@src/components/Cards';
import { useMediaQuery } from '@src/helpers/media';

import styles from './article.module.scss';

const ArticleName = {
  monetaEcosystem: 'monetaEcosystem',
  ecosystemMetaverse: 'ecosystemMetaverse',
  monetaMainnet: 'monetaMainnet',
};

const ArticlePage = observer(({ title, text, article }) => {
  const stores = useStores();
  const _ = stores.language;

  /* useEffect for Google Analytics */
  /* const location = useLocation();
  useEffect(() => {
    window.gtag('event', 'visitToArticlePage', {
      page_title: location.pathname,
    });
    console.log('visitToArticlePage: ', location.pathname);
  }, [location]); */

  return (
    <Layout>
      <Content title={title} text={text} />
      <Navigation article={article} />
    </Layout>
  );
});

const Content = ({ title, text }) => {
  return (
    <div className={styles.MainContainer}>
      <p className={styles.Title}>{title}</p>
      <p className={styles.Text} dangerouslySetInnerHTML={{ __html: text }} />
    </div>
  );
};

/*  Костя, тут будет при клике на каждый баннер отправляться события в Google аналитику
    И везде надо будет вставить эту строчку с названием карточки

    onClick={() => {
      gtagEvent('click<CardName>CardInArticlePage');
    }}
*/
const Navigation = ({ article }) => {
  const size = CardSize.Medium;
  const isWidth1420 = useMediaQuery('(max-width: 1420px)');

  return (
    <div className={styles.Navigation}>
      {article !== ArticleName.monetaEcosystem && (
        <Link
          to="/articles/moneta-today-ecosystem"
          target="_top"
          onClick={() => {
            gtagEvent(GOOGLE_ANALYTICS_CONSTANTS.CLICK_SUMMARY_ARTICLE);
          }}
        >
          <SummaryCard size={isWidth1420 ? 'SizeSmall' : size} />
        </Link>
      )}
      {article !== ArticleName.ecosystemMetaverse && (
        <Link
          to="/articles/ecosystem-or-metaverse"
          target="_top"
          onClick={() => {
            gtagEvent(GOOGLE_ANALYTICS_CONSTANTS.CLICK_ECOSYSTEM_ARTICLE);
          }}
        >
          <EcosystemCard size={isWidth1420 ? 'SizeSmall' : size} />
        </Link>
      )}
      {article !== ArticleName.monetaMainnet && (
        <Link
          to="/articles/moneta-today-mainnet"
          target="_top"
          onClick={() => {
            gtagEvent(GOOGLE_ANALYTICS_CONSTANTS.CLICK_MAINNET_ARTICLE);
          }}
        >
          <MainnetCard size={isWidth1420 ? 'SizeSmall' : size} />
        </Link>
      )}
    </div>
  );
};

export { ArticleName, ArticlePage };
