/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useStores } from '@src/stores';
import Layout from '@src/components/Layout';

const ArticlesPage = observer(() => {
  const navigate = useNavigate();
  const stores = useStores();
  const _ = stores.language;

  /* useEffect for Google Analytics */
  /* const location = useLocation();
  useEffect(() => {
    window.gtag('event', 'visitToInvestingPage', {
      page_title: location.pathname,
    });
    // console.log('visitToInvestingPage: ', location.pathname);

    navigate(`/articles/moneta-today-ecosystem`);
  }, [location]); */


  return (
    <Layout>
      <p>Articles</p>
    </Layout>
  );
});


export default ArticlesPage;
