import React from 'react';
import { gtagEvent } from '@utils/common';
import { GOOGLE_ANALYTICS_CONSTANTS } from '@src/constants/googleAnalyticsEvents';
// import SolarSystem from '@src/assets/images/SolarSystem.png';
import SolarSystem from '@src/assets/svg/SolarSystem.svg';
import Button from '@src/components/Button';

import styles from './service.module.scss';

const ServicesSection = ({ open, stores }) => {
  return (
    <div className={styles.Services}>
      <img src={SolarSystem} alt="SolarSystem" className={styles.SolarSystem} />
      {/* <SolarSystem className={styles.SolarSystem} /> */}
      <div className={styles.ServicesContent}>
        <p className={styles.ServicesTitle}>{stores.translate('home_title')}</p>
        <p className={styles.ServicesText}>{stores.translate('home_description')}</p>
        {/* <Button
          onClick={() => {
            gtagEvent(GOOGLE_ANALYTICS_CONSTANTS.CLICK_JOIN_TOP);
            open();
          }}
          text={stores.translate('join')}
          classNames={{ Container: stores.language === 'en' && styles.ServiceButtonEng }}
        /> */}
      </div>
    </div>
  );
};

export default ServicesSection;
