import React from 'react';
import { useMediaQuery } from '@src/helpers/media';
import MobileHomePage from '@src/pages/HomePage/components/MobileHomePage';
import DesktopHomePage from '@src/pages/HomePage/components/DesktopHomePage';
import { observer } from 'mobx-react';
import { useStores } from '@src/stores';

const HomePage = observer(() => {
  const stores = useStores();
  const isMobileSize = useMediaQuery('(max-width: 570px)');

  /* Для переключения режимов разработки, чтобы можно было через Google Chrome дебажить, 
  перед заливкой в прод не забудь вернуть обратно! */
  // return isMobileSize ? (
  return stores.browserInfo.isMobile || (isMobileSize && !stores.browserInfo.isMobile) ? (
    <MobileHomePage />
  ) : (
    <DesktopHomePage />
  );
});

export default HomePage;
