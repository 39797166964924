import React from 'react';
import { types } from 'mobx-state-tree';
import { DEFAULT_LANGUAGE } from '@src/constants/language';
import i18n from '@src/localization';

const AppStore = types
  .model({
    language: types.optional(types.string, DEFAULT_LANGUAGE),
    browserInfo: types.optional(types.frozen(null)),
    isInstagramMobile: types.optional(types.boolean, false),
  })
  .actions((self) => ({
    afterCreate() {
      const language = window.localStorage.getItem('language');
      if (language === 'en' || language === 'ru') {
        window.localStorage.setItem('language', DEFAULT_LANGUAGE);
        self.language = DEFAULT_LANGUAGE;
      } else if (language) {
        self.language = language;
      }
      navigator.sayswho = (() => {
        let tem;
        const ua = navigator.userAgent;
        let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];

        if (/trident/i.test(M[1])) {
          tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
          return ['IE ', tem[1] || ''];
        }
        if (M[1] === 'Chrome') {
          tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
          if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
        }
        M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
        tem = ua.match(/version\/(\d+)/i);
        if (tem != null) M.splice(1, 1, tem[1]);
        return M;
      })();

      const isMobile = /iPhone|Android/i.test(navigator.userAgent);
      const isAndroid = /Android/i.test(navigator.userAgent);

      const browserUse = navigator.sayswho;
      if (browserUse && browserUse.length === 2) {
        const browserName = browserUse[0];
        let isOldBrowser = true;
        const majorNumberVersion = Number(browserUse[1].split('.')[0] || 1);
        if (
          (browserName.toLowerCase() === 'safari' && majorNumberVersion >= 15) ||
          (browserName.toLowerCase() === 'firefox' && majorNumberVersion >= 55) ||
          (browserName.toLowerCase() === 'chrome' && majorNumberVersion >= 54) ||
          (browserName.toLowerCase() === 'opera' && majorNumberVersion >= 41)
        ) {
          isOldBrowser = false;
        }
        self.browserInfo = { name: browserUse[0], version: browserUse[1], isOldBrowser, isMobile, isAndroid };
      } else {
        self.browserInfo = { isMobile };
      }
    },
    translate(text) {
      return i18n.t(text, { locale: self.language });
    },
    setLanguage(language) {
      self.language = language;
    },
    setInstagramMobile(isInstagramMobile) {
      self.isInstagramMobile = isInstagramMobile;
    },
  }));

export const StoreInstance = AppStore.create({});
export const ContextStore = React.createContext(StoreInstance);
export const ProviderStore = ContextStore.Provider;

export const useStores = () => {
  return React.useContext(ContextStore);
};
