import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { gtagEvent } from '@utils/common';
import { GOOGLE_ANALYTICS_CONSTANTS } from '@src/constants/googleAnalyticsEvents';
// import { ALL_LANGUAGES } from '@src/constants/language';
import i18n from '@src/localization';
import { useStores } from '@src/stores';

import Select from '@src/components/Select';
import MonetaToday from '@src/assets/svg/MonetaToday.svg';

import styles from './header.module.scss';

const Header = observer(() => {
  const stores = useStores();
  // const [hoverLanguage, setHoverLanguage] = useState(false);

  const [otherLanguages, setOtherLanguages] = useState(
    i18n.languages.filter((item) => item !== stores.language),
  );

  return (
    <div className={styles.MainContainer}>
      <a href="/" className={styles.MonetaTodayLink}>
        <img src={MonetaToday} alt="MonetaToday" loading="lazy" />
      </a>
      <div className={styles.LanguageToggle}>
        <Select
          // label="Listings"
          value={stores.language}
          values={otherLanguages}
          // values={['Eng', 'Rus', 'Est', 'Esp']}
          onChange={(selected) => {
            gtagEvent(GOOGLE_ANALYTICS_CONSTANTS.CLICK_LANGUAGETOGGLE);
            // const symbolLanguage = stores.language === 'en' ? 'ru' : 'en';
            stores.setLanguage(selected);
            window.localStorage.setItem('language', selected);
            setOtherLanguages(i18n.languages.filter((item) => item !== stores.language));
            // e.preventDefault();
          }}
          classNames={{
            SelectContainer: styles.SelectContainer,
            Header: styles.SelectHeader,
            DropDownList: styles.SelectDropDownList,
            ListItem: styles.SelectListItem,
          }}
        />
      </div>
    </div>
  );
});

export default Header;
