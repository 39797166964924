import React from 'react';
import Bugsnag from '@bugsnag/js';
// import * as Sentry from "@sentry/react";
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { StoreInstance } from '@src/stores';
import { DEVELOPMENT, PRODUCTION, APP_VERSION } from '@constants/system';

Bugsnag.start({
  apiKey: '85cd8ec5770cefdba2eb60d3b2441cc1',
  plugins: [new BugsnagPluginReact()],
  releaseStage: process.env.REACT_APP_STAGE || DEVELOPMENT,
  enabledReleaseStages: [PRODUCTION, DEVELOPMENT],
  appVersion: APP_VERSION,
});

export const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

const Logger = {
  error(e) {
    if (process.env.REACT_APP_STAGE !== PRODUCTION) {
      console.log(e);
    }
    Bugsnag.notify(e);
  },
  /** @not use */
  info(...args) {
    console.log(args);
  },
  /** @not use */
  infoMain(arg, useInProduction = false) {
    if (process.env.REACT_APP_STAGE !== PRODUCTION) {
      console.log(`${arg} >> `, arg);
    } else if (useInProduction) {
      this.infoNotify('argument', arg);
    }
  },
  /** @info without bugsnag notify */
  infoMainWithKey(key, ...values) {
    let useInProduction;
    if (
      values.length > 0 &&
      values[values.length - 1] &&
      values[values.length - 1].useInProduction
    ) {
      ({ useInProduction } = values[values.length - 1]);
    }

    if (process.env.REACT_APP_STAGE !== PRODUCTION || useInProduction) {
      if (values.length < 1) {
        console.log(`${key} >> `);
      } else {
        console.log(`${key} >> `, values);
      }
      if (!process.env.REACT_APP_STAGE && StoreInstance.localStackLogger) {
        console.log(new Error().stack.substring(0, 300));
      }
    }
  },
  infoNotify(section, meta, useInProduction = false) {
    if (process.env.REACT_APP_STAGE !== PRODUCTION || useInProduction) {
      Bugsnag.addMetadata(section, meta);
      Bugsnag.notify(new Error('"info notify"'));
    }
  },
  infoMainNotify({ nameNotify = 'info notify', section, meta, useInProduction = false }) {
    if (process.env.REACT_APP_STAGE !== PRODUCTION || useInProduction) {
      Bugsnag.addMetadata(section, meta);
      Bugsnag.notify(new Error(`${nameNotify}`));

      // Sentry.configureScope(
      //   scope => scope
      //     .setLevel("Error")
      //     .setUser({ user: null })
      //     .setExtra("meta", meta)
      // );
      // Sentry.captureException(new Error(`${nameNotify}`));
      // Sentry.captureMessage(`Something went wrong ${JSON.stringify(meta)}`);
    }
  },
};

export default Logger;
