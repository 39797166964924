/* eslint-disable no-unused-vars */
import React from 'react';
import Close from '@src/assets/svg/Close.svg';
import styles from './mobilePopup.module.scss';

const MobilePopUp = ({ isPopUpOpen, closePopUp, stores }) => {
  return (
    isPopUpOpen && (
      <div className={styles.PopUpContainer}>
        <div className={styles.PopUp}>
          <button
            type="button"
            className={styles.PopUpClose}
            onClick={() => {
              closePopUp();
            }}
          >
            <img src={Close} alt="Close" loading="lazy" />
          </button>
          {/* <p
            className={styles.PopUpTitle}
            dangerouslySetInnerHTML={{ __html: stores.translate('popup_after_send') }}
          /> */}
          <p
            className={styles.PopUpText}
            dangerouslySetInnerHTML={{ __html: stores.translate('popup_after_send') }}
          />
        </div>
      </div>
    )
  );
};

export default MobilePopUp;
