/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { gtagEvent } from '@utils/common';
import { GOOGLE_ANALYTICS_CONSTANTS } from '@src/constants/googleAnalyticsEvents';
import { useStores } from '@src/stores';
import Layout from '@src/components/Layout';
import Button from '@src/components/Button';

import Twitter from '@src/assets/svg/Twitter.svg';
import Chat from '@src/assets/svg/Chat.svg';
import TelegramPlane from '@src/assets/svg/TelegramPlane.svg';
import Facebook from '@src/assets/svg/Facebook.svg';
import Web from '@src/assets/svg/Web.svg';
import Envelope from '@src/assets/svg/Envelope.svg';
import HoneyHeaderMobile from '@src/assets/svg/honey_header_mobile.svg';
import BearHoneywoodMobile from '@src/assets/svg/bear_honeywood_mobile.svg';

import Modal from '@src/pages/HomePage/components/DesktopHomePage/components/Modal';
import PopUp from '@src/pages/HomePage/components/MobileHomePage/components/MobilePopUp';

import styles from './mobileHoneywood.module.scss';

/* For prevent scrolling to page's bottom in Modal mode */
let yOffset = 0;

const MobileHoneyWoodPage = observer(() => {
  const stores = useStores();
  const _ = stores.language;
  const isAndroid = /(android)/i.test(navigator.userAgent);
  const [isOpen, setIsOpen] = useState(false);
  const [isPopUpOpen, setPopUpOpen] = useState(false);

  /* For prevent scrolling to page's bottom in Modal mode */
  useEffect(() => {
    if (isAndroid && !isOpen) {
      window.scrollTo(0, yOffset);
    } else if (isAndroid && isOpen) {
      yOffset = window.pageYOffset;
      window.scrollTo(0, 0);
    }
  }, [isOpen]);

  /* useEffect for Google Analytics */
  const location = useLocation();
  useEffect(() => {
    gtagEvent(GOOGLE_ANALYTICS_CONSTANTS.VISIT_HONEYWOOD);
  }, [location]);

  const open = () => {
    /* Dynamically lock scrolling background */
    document.body.style.overflow = 'hidden';
    setIsOpen(true);
  };

  const close = () => {
    /* Dynamically unlock scrolling background */
    document.body.style.overflow = 'visible';
    setIsOpen(false);
  };

  const closePopUp = () => {
    /* Dynamically unlock scrolling background */
    document.body.style.overflow = 'visible';
    setPopUpOpen(false);
  };

  const openPopUp = () => {
    /* Dynamically lock scrolling background */
    document.body.style.overflow = 'hidden';
    setPopUpOpen(true);
    setTimeout(closePopUp, 3000);
  };

  return (
    <Layout>
      <div className={styles.Container}>
        <img src={HoneyHeaderMobile} className={styles.HoneyHeader} alt="HoneyHeaderMobile" />
        <div className={styles.MainContainer}>
          <Content stores={stores} open={open} />
          <Socials />
          <img src={BearHoneywoodMobile} className={styles.BearImage} alt="BearHoneywoodMobile" />
        </div>
        <Modal isOpen={isOpen} close={close} stores={stores} openPopUp={openPopUp} fromHoneyWood />
        <PopUp isPopUpOpen={isPopUpOpen} closePopUp={closePopUp} stores={stores} />
      </div>
    </Layout>
  );
});

const Content = ({ stores, open }) => {
  const icoStart = stores.translate('honeywood_ico');
  const cryptoGame = stores.translate('honeywood_crypto_game');
  const gameCommissions = stores.translate('honeywood_game_commissions');
  const roadmap = stores.translate('honeywood_roadmap');

  return (
    <div className={styles.ContentContainer}>
      <p
        className={classNames(styles.Title, styles.FirstTitle)}
        dangerouslySetInnerHTML={{ __html: stores.translate('article_honeywood_title') }}
      />
      <div className={styles.ContentSection}>
        <p
          className={styles.Title}
          dangerouslySetInnerHTML={{ __html: stores.translate('honeywood_ico_section') }}
        />
        {icoStart &&
          icoStart.map((strengthProject, index) => {
            return (
              <div
                className={classNames(
                  styles.StrengthsContainer,
                  index > 4 && styles.TextMarginRight,
                )}
                key={strengthProject.title}
              >
                <div className={styles.StrengthPoint} />
                <p className={styles.Text}>&nbsp;{strengthProject.title}</p>
              </div>
            );
          })}
      </div>
      <div className={styles.ContentSection}>
        <p
          className={styles.Title}
          dangerouslySetInnerHTML={{ __html: stores.translate('honeywood_crypto_game_section') }}
        />
        {cryptoGame &&
          cryptoGame.map((strengthProject, index) => {
            return (
              <div
                className={classNames(
                  styles.StrengthsContainer,
                  index > 4 && styles.TextMarginRight,
                )}
                key={strengthProject.title}
              >
                <div className={styles.StrengthPoint} />
                <p className={styles.Text}>&nbsp;{strengthProject.title}</p>
              </div>
            );
          })}
      </div>
      <div className={styles.ContentSection}>
        <p
          className={styles.Title}
          dangerouslySetInnerHTML={{
            __html: stores.translate('honeywood_game_commissions_section'),
          }}
        />
        {gameCommissions &&
          gameCommissions.map((strengthProject, index) => {
            return (
              <div
                className={classNames(
                  styles.StrengthsContainer,
                  index > 4 && styles.TextMarginRight,
                )}
                key={strengthProject.title}
              >
                <div className={styles.StrengthPoint} />
                <p className={styles.Text}>&nbsp;{strengthProject.title}</p>
              </div>
            );
          })}
      </div>
      <div className={styles.ContentSection}>
        <p
          className={styles.Title}
          dangerouslySetInnerHTML={{ __html: stores.translate('honeywood_roadmap_section') }}
        />
        {roadmap &&
          roadmap.map((strengthProject, index) => {
            return (
              <div
                className={classNames(
                  styles.StrengthsContainer,
                  index > 4 && styles.TextMarginRight,
                )}
                key={strengthProject.title}
              >
                <div className={styles.StrengthPoint} />
                <p className={styles.Text}>&nbsp;{strengthProject.title}</p>
              </div>
            );
          })}
      </div>
      <p
        className={classNames(styles.Text, styles.LastTitle)}
        dangerouslySetInnerHTML={{ __html: stores.translate('leave_your_email') }}
      />

      <div className={styles.ButtonContainer}>
        <Button
          onClick={() => {
            gtagEvent(GOOGLE_ANALYTICS_CONSTANTS.CLICK_JOIN_HONEYWOOD);
            open();
          }}
          text={stores.translate('learn_more')}
          classNames={{ Container: stores.language === 'en' && styles.ButtonEng }}
        />
      </div>
    </div>
  );
};

const LinkIcon = ({ link, icon, onClick, isSmall, classes = {} }) => {
  return (
    <div className={styles.SocialItem}>
      <a href={link} target="_blank" rel="noreferrer" className={styles.LinkItem} onClick={onClick}>
        <img
          src={icon}
          alt="icon"
          loading="lazy"
          width={isSmall ? 30 : 40}
          height={isSmall ? 30 : 40}
          className={classNames(classes)}
        />
      </a>
    </div>
  );
};

const Socials = () => {
  return (
    <div className={classNames(styles.SocialContainer, styles.PaddingSocial)}>
      <LinkIcon
        link="mailto:contact@moneta.today"
        icon={Envelope}
        isSmall
        classes={styles.Envelope}
        onClick={() => {
          gtagEvent(GOOGLE_ANALYTICS_CONSTANTS.CLICK_MAIL);
        }}
      />
      <LinkIcon
        link="http://honeywood.io"
        icon={Web}
        isSmall
        classes={styles.Web}
        onClick={() => {
          gtagEvent(GOOGLE_ANALYTICS_CONSTANTS.CLICK_HONEYWOOD_WEB);
        }}
      />

      <LinkIcon
        link="https://t.me/honeywood_chat"
        icon={Chat}
        isSmall
        classes={styles.Chat}
        onClick={() => {
          gtagEvent(GOOGLE_ANALYTICS_CONSTANTS.CLICK_HONEYWOOD_CHAT);
        }}
      />
      <LinkIcon
        link="https://t.me/honeywood_game"
        icon={TelegramPlane}
        isSmall
        classes={styles.TelegramPlane}
        onClick={() => {
          gtagEvent(GOOGLE_ANALYTICS_CONSTANTS.CLICK_TELEGRAM);
        }}
      />
      <LinkIcon
        link="https://www.facebook.com/groups/honeywoodgame"
        icon={Facebook}
        isSmall
        classes={styles.Facebook}
        onClick={() => {
          gtagEvent(GOOGLE_ANALYTICS_CONSTANTS.CLICK_FACEBOOK);
        }}
      />
      <LinkIcon
        link="https://twitter.com/honeywood_game"
        icon={Twitter}
        isSmall
        classes={styles.Twitter}
        onClick={() => {
          gtagEvent(GOOGLE_ANALYTICS_CONSTANTS.CLICK_TWITTER);
        }}
      />
    </div>
  );
};

export default MobileHoneyWoodPage;
