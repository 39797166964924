const lightLime = '#84FB16';
const lime = '#41E20C';
const green = '#1A9E39';
const forestGreen = '#03AA10';

const yellow = '#FAFF00';
const gold = '#FFB800';
const darkOrange = '#EA6400';
const orangeRed = '#CC460B';

const magenta = '#FF00B8';
const violet = '#E633FF';
const mediumOrchid = '#AE00FE';
const darkOrchid = '#9C0ABE';

const hoverRed = '#FC7326';
const lightRed = '#FF8A35';
const strokeRed = '#DB2433';
const darkRed = '#E11142'; 
const lightActiveRed = '#8C151F';

export const COLORS_BUTTON = {
  GREEN: {
    lightColor: lime,
    darkColor: green,
    strokeColor: forestGreen,
    hoverColor: lightLime,
    lightActiveColor: lime,
  },
  ORANGE: {
    lightColor: gold,
    darkColor: darkOrange,
    strokeColor: orangeRed,
    hoverColor: yellow,
    lightActiveColor: gold,
  },
  PURPLE: {
    lightColor: violet,
    darkColor: mediumOrchid,
    strokeColor: darkOrchid,
    hoverColor: magenta,
    lightActiveColor: violet,
  },
  RED: {
    lightColor: lightRed,
    darkColor: darkRed,
    strokeColor: strokeRed,
    hoverColor: hoverRed,
    lightActiveColor: lightActiveRed,
  }
};

export default {
  COLORS_BUTTON,
};
